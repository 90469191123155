import React from "react";

import { Card, Typography, Grid } from "@material-ui/core";
import { GenerateKantoFinanceBureauDirectorNo } from "../../../utils/generateKantoFinanceBureauDirectorNo";

import "./styles.css";

export default function Footer() {
  return (
    <Grid item xs={12}>
      <Card
        className="ReservationForm-footerHolder"
        style={{ borderRadius: 0 }}
      >
        <div className="ReservationForm-footerAboveText">
          <p>ＳＭＢＣコンシューマーファイナンス株式会社</p>
          <p>電話：03-6887-1515（代表）</p>
          <p>
            登録番号:関東財務局長(
            {GenerateKantoFinanceBureauDirectorNo.generateKantoFinanceBureauDirectorNo()}
            ) 第00615号
          </p>
          <p>日本貸金業協会会員 第000001号</p>
        </div>
        <p className="ReservationForm-footerBelowText">
          Copyright SMBC Consumer Finance Co., Ltd. All rights reserved.
        </p>
      </Card>
    </Grid>
  );
}
