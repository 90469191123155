import * as React from "react";
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  Button,
  Box,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Translation } from "react-i18next";
import Appbar from "../Layout/AppBar";
import IFeedbackStates from "../../interfaces/feedback";
import "./style.css";

interface Props extends RouteComponentProps<any> {}

class Feedback extends React.Component<Props, IFeedbackStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      callQuality: "",
      serviceQuality: "",
      comment: "",
    };
  }

  componentDidMount() {}

  handleOnClickShowHome = () => {
    this.props.history.push("/home");
  };

  onHandleChangeCallRatingQuality(data: any) {
    this.setState({ callQuality: data.target.value });
  }

  onHandleChangeCallServiceQuality(data: any) {
    this.setState({ serviceQuality: data.target.value });
  }

  onHandleChangeCommentInput(data: any) {
    this.setState({ comment: data.target.value });
  }

  validateAllFieldsPopulated() {
    return this.state.callQuality !== "" &&
      this.state.serviceQuality !== "" &&
      this.state.comment !== ""
      ? true
      : false;
  }

  render() {
    let isPopulated = this.validateAllFieldsPopulated();

    return (
      <React.Fragment>
        <Translation>
          {(t, { i18n }) => (
            <Grid container className="Feedback-grid-container">
              <Grid item xs={12}>
                <Appbar appbarTitle={"Feedback"} withCallback={false} />
              </Grid>

              <Grid container justify="center">
                <Grid item xs={12}>
                  <Card className="Feedback-card">
                    <CardHeader
                      title={
                        <Typography className="Feedback-header">
                          <span className="Feedback-header">
                            {t(
                              "Thank you for using the smartphone window. We look forward to hearing from you in order to provide better services in the future."
                            )}
                          </span>
                        </Typography>
                      }
                      className="Feedback-card-header"
                    />
                    <CardContent className="Feedback-card-content">
                      <div>
                        <Typography className="Feedback-text-label">
                          {t("How was the call quality?")}
                        </Typography>
                        <Box
                          component="fieldset"
                          mb={3}
                          borderColor="transparent"
                        >
                          <Rating
                            name="callQuality"
                            value={this.state.callQuality || 0}
                            onChange={this.onHandleChangeCallRatingQuality.bind(
                              this
                            )}
                          />
                        </Box>
                        <Typography className="Feedback-text-label">
                          {t("How was the response of the person in charge?")}
                        </Typography>
                        <Box
                          component="fieldset"
                          mb={3}
                          borderColor="transparent"
                        >
                          <Rating
                            name="serviceQuality"
                            value={this.state.serviceQuality || 0}
                            onChange={this.onHandleChangeCallServiceQuality.bind(
                              this
                            )}
                          />
                        </Box>
                        <Typography className="Feedback-text-label">
                          {t("Please write any additional comments.")}
                        </Typography>
                        <textarea
                          onChange={this.onHandleChangeCommentInput.bind(this)}
                          className="Feedback-comment-input-style"
                        ></textarea>

                        <Grid
                          container
                          direction="column"
                          justify="center"
                          className="Feedback-grid"
                        >
                          <Grid item xs={12}>
                            <Button
                              className={`Feedback-send-button ${
                                isPopulated ? "enable" : ""
                              } `}
                              disabled={isPopulated ? false : true}
                              onClick={this.handleOnClickShowHome}
                            >
                              {t("Send")}
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              className={"Feedback-close-button"}
                              onClick={this.handleOnClickShowHome}
                            >
                              {t("Close")}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}

export default withRouter(Feedback);
