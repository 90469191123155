import moment from "moment";

export const convertDateFormat = (
  d: any,
  format: string,
  convertToDateObj: boolean = false
) => {
  if (!d) d = new Date();
  const date = moment(d).format(format || "MM-DD-YYYYTHH:mm:ss");
  if (convertToDateObj) return new Date(date);
  else return date;
};

export const convertDate = (date: any, format: string) => {
  return new Date(moment(date).format(format));
};
