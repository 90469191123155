export const checkDeviceIfMobile = (): boolean => {
  return window.matchMedia("only screen and (max-width: 760px)").matches;
};

export const checkDeviceIfLaptop = (): boolean => {
  return window.matchMedia("only screen and (max-width: 1600px)").matches;
};

export const checkDeviceIfTablet = (): boolean => {
  return window.matchMedia("only screen and (max-width: 1224px)").matches;
};

export const checkDeviceSize = (size: number): boolean => {
  return window.matchMedia(`only screen and (max-height: ${size}px)`).matches;
};

export const validEmailRegex = RegExp(
  /* eslint-disable */
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const checkUndefined = (
  data: string,
  stateItem: string | Date,
  parsed: any = null
) => {
  if (data === undefined || data === "" || data === "Invalid date") {
    return stateItem;
  } else if (parsed) {
    return parsed;
  } else {
    return data;
  }
};
