import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Grid, CardContent, Typography } from "@material-ui/core";
import PhoneEnabledIcon from "../Common/PhoneEnabledIcon";

import "./styles.css";

interface Props extends RouteComponentProps<any> {}

const APP_OPERATOR_PHONE_NUMBER = process.env.REACT_APP_OPERATOR_PHONE_NUMBER;
const APP_CALL_OPERATOR = process.env.REACT_APP_CALL_OPERATOR;

class OperatorPhoneNumber extends React.Component<Props> {
  render() {
    return (
      <div className="OperatorPhoneNumber-card-holder">
        <CardContent>
          <Grid className="OperatorPhoneNumber-subcard-text-header">
            <div className="OperatorPhoneNumber-free-call">
              ご相談・ご質問はプロミスコールへ
            </div>
          </Grid>
          <Grid className="OperatorPhoneNumber-phone-card-holder">
            <PhoneEnabledIcon
              className="OperatorPhoneNumber-phone-icon"
              fill="#00356c"
            />
            <a
              href={APP_CALL_OPERATOR}
              className="OperatorPhoneNumber-phone-number"
            >
              {APP_OPERATOR_PHONE_NUMBER}
            </a>
          </Grid>
          <Grid>
            <Typography className="OperatorPhoneNumber-time-text">
              受付時間: 平日9:00 - 18:00
            </Typography>
          </Grid>
        </CardContent>
      </div>
    );
  }
}

export default withRouter(OperatorPhoneNumber);
