import React from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import PromiseLogo from "../../assets/images/LogoPromise.png";
import PhoneEnabledIcon from "../Common/PhoneEnabledIcon";
import { GenerateKantoFinanceBureauDirectorNo } from "../../utils/generateKantoFinanceBureauDirectorNo";

const Error = () => {
  return (
    <div>
      <div className="error-inner">
        <div className="content-inner">
          キャッシング・カードローンのことなら消費者金融会社ＳＭＢＣコンシューマーファイナンスのプロミス
        </div>
      </div>
      <div className="header-content-pc">
        <div className="header-content-all">
          <div className="header-content">
            <div className="header-content-logo">
              <img alt="img" src={PromiseLogo} className="Error-page-logo" />
            </div>
            <div className="header-content-button">
              <p className="text-top">ご相談・ご質問はプロミスコールへ</p>
              <div className="text-top-phone">
                <div>
                  <PhoneEnabledIcon className="phone-icon" fill="#00356c" />
                </div>
                <p className="text-bottom">0120-24-0365</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div bdr-normal="" className="header-border-normal"></div>

      <div className="content-box">
        <main>
          <h1>ご指定のページは表示できません</h1>
          <p>お手数ですが、スマートフォンにて再度アクセスしてください。</p>
        </main>
      </div>

      <div className="footer-border-normal">
        <div className="footer-text">
          <p>ＳＭＢＣコンシューマーファイナンス株式会社</p>
          <p>電話：03-6887-1515（代表）</p>
          <p>
            登録番号：関東財務局長（
            {GenerateKantoFinanceBureauDirectorNo.generateKantoFinanceBureauDirectorNo()}
            ）第00615号
          </p>
          <p>日本貸金業協会会員 第000001号</p>
        </div>
        <div className="footer-text-bottom">
          <p>Copyright SMBC Consumer Finance Co., Ltd.</p>
          <p>All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Error);
