export const characterOnly = (e: any) => {
  if (
    e.which === 32 ||
    (e.which >= 48 && e.which <= 57) ||
    (e.which >= 96 && e.which <= 105)
  ) {
    e.preventDefault();
  }
  return true;
};
