import * as React from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./styles.css";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import OperatorPhoneNumber from "../OperatorPhoneNumber";

const customNet = process.env.REACT_APP_PROMISE_ADDRESS;
class EndPage extends React.Component<any, any> {
  _isMounted = false;
  constructor(props: any) {
    super(props);
    this.state = {
      holder: this?.props?.location?.state,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
  }

  goToReview() {
    if (this.props.clickEventHandler) {
      this.props.clickEventHandler();
    } else {
      window.location.href = customNet!.toString();
    }
  }

  topFunction = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <Grid item xs={12}>
            <div className="ReservationForm-form">
              <div className="ReservationForm-Title">{this.props.title}</div>
              {this.props.noticeInfo !== "" && (
                <div className="EndPage-text-above">
                  {this.props.noticeInfo}
                </div>
              )}
              {this.props.content && (
                <div className="EndPage-text-below">{this.props.content}</div>
              )}
              {this.props.innerHTML && (
                <div
                  className="EndPage-text-below"
                  dangerouslySetInnerHTML={{ __html: this.props.innerHTML }}
                />
              )}
              {this.props.operatorPhoneNumber && <OperatorPhoneNumber />}
              {this.props.spaceArea && <div className="Space-area" />}
              {this.props.displayButton && (
                <button
                  onClick={() => this.goToReview()}
                  className="ReservationForm-returnButton"
                >
                  {this.props.buttonName}
                </button>
              )}
              <button
                onClick={() => this.topFunction()}
                className="ReservationForm-backToTopButton"
              >
                <ArrowUpwardIcon />
                このページのTOPへ戻る
              </button>
            </div>
          </Grid>
        )}
      </Translation>
    );
  }
}

export default withRouter(EndPage);
