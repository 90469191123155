import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { Translation } from "react-i18next";
import "moment/locale/ja";

import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Grid,
  withStyles,
} from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import popOutIcon from "../../../assets/images/pop-outline.png";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

// modal component
import TermsAndConditionModal from "./TermsAndConditionModal";
import NeedToWait from "./NeedToWait";

// interface
import {
  ReservationFormProps,
  ReservationFormState,
  ReservationFormStyle,
} from "../../../interfaces/Reservation";

// utils
import { checkUndefined } from "../../../utils/common";
import { characterOnly } from "../../../utils/characterOnly";
import { TextUtils } from "../../../utils/text-utils";

// api
import { getCustomerReservationByMeetingID } from "../../../api/index";
import DateSelect from "../../Common/DateDropList";
import StepBar from "../../Common/StepBar";
import NoAvailableOperator from "./NoAvailableOperator";

const firstNum = process.env.REACT_APP_JAPAN_FIRST_CODE;
const secondNum = process.env.REACT_APP_JAPAN_SECOND_CODE;
const thirdNum = process.env.REACT_APP_JAPAN_THIRD_CODE;
const onlineInstruction = process.env.REACT_APP_PROMISE_ONLINE_INSTRUCTION;
const reg = new RegExp("^[0-9]*$");

const ReservationForm: FunctionComponent<ReservationFormProps> = (props) => {
  const {
    holder,
    agentsInPreparing,
    noAvailableOperator,
    availableAgents,
    inPreparingAgents,
  } = props;
  const [disabled, setDisabled] = useState(false);
  const [inputFlag, setInputFlag] = useState<ReservationFormStyle>({
    firstName: false,
    lastName: false,
    firstNameFurigana: false,
    lastNameFurigana: false,
    phoneNumber: false,
    phoneType: false,
    clickEventHandling: false,
  });
  const [formState, setFormState] = useState<ReservationFormState>({
    first_name: "",
    last_name: "",
    firstNameFurigana: "",
    lastNameFurigana: "",
    birthday: "",
    phoneNumber: "",
    phoneType: "",
    errors: {
      errorFirstName: false,
      errorFirstNameMess: "",
      errorLastName: false,
      errorLastNameMess: "",
      errorFuriganaFirstName: false,
      errorFuriganaFirstNameMess: "",
      errorFuriganaLastName: false,
      errorFuriganaLastNameMess: "",
      bday: new Date(),
      mobileNumber: "",
      phone: "",
      errorMessage: "",
      errorCard: false,
      errorNumber: "",
      errNumMess: false,
      errNumChar: false,
      errNumCharMess: "",
      errorDate: false,
    },
    hasValidationErrors: false,
    validationErrMsg: "",
    validationErrors: {
      lastNameKanji: false,
      firstNameKanji: false,
      lastNameKana: false,
      firstNameKana: false,
      birthday: false,
      phoneNumber: false,
      phoneType: false,
      birthdayYear: false,
      birthdayMonth: false,
      birthdayDay: false,
    },
    holder: holder.location.state,
    reservationType: "",
    TermsAndConditionModal: false,
    errData: {},
    TermsAndConditionCloseModal: false,
    birthdayYear: "",
    birthdayMonth: "",
    birthdayDay: "",
    firstSeletY: false,
    firstSeletM: false,
    firstSeletD: false,
    selectYearFlag: false,
    selectMonthFlag: false,
    selectDayFlag: false,
    availableAgents: 0,
    inPreparingAgents: 0,
  });
  var modalRef = useRef<any>(null);
  var touchStartY = 0;

  const getCustomerReservation = async (meetingId: any) => {
    try {
      const jsondata = await getCustomerReservationByMeetingID(meetingId);
      setFormState((prevState) => ({
        ...prevState,
        reservationDetails: jsondata,
        first_name: checkUndefined(
          jsondata[0].first_name,
          formState.first_name
        ),
        last_name: checkUndefined(jsondata[0].last_name, formState.last_name),
        lastNameFurigana: checkUndefined(
          jsondata[0].last_name,
          formState.lastNameFurigana
        ),
        firstNameFurigana: checkUndefined(
          jsondata[0].last_name,
          formState.firstNameFurigana
        ),
        birthday: checkUndefined(jsondata[0].birthday, formState.birthday),
        phoneNumber: checkUndefined(
          jsondata[0].phoneNumber,
          formState.phoneNumber,
          jsondata[0].phoneNumber.replace("+", "")
        ),
        phoneType: checkUndefined(jsondata[0].phone_type, formState.phoneType),
        reservationType: checkUndefined(
          jsondata[0].meeting_date,
          "Consult Now"
        ),
      }));
    } catch (e) {
      console.log(e, "sever error");
    }
  };

  // this effect only loads once
  useEffect(() => {
    if (formState.holder?.hasOwnProperty("meetingId")) {
      getCustomerReservation(formState.holder.meetingId);
      setFormState((prevState) => ({
        ...prevState,
        holder: holder.location,
      }));
    } else {
      let states: { [key: string]: any } = {};
      if (formState.holder === undefined) {
        states = formState;
      } else {
        states = formState.holder;
      }
      setFormState((prevState) => ({
        ...prevState,
        ...states,
        availableAgents: availableAgents,
        inPreparingAgents: inPreparingAgents,
      }));

      if (states?.errData?.fields?.length > 0) {
        showInvalidFields(states.errData.fields);
      }
    }
    // eslint-disable-next-line
  }, []);

  const showInvalidFields = (e: any) => {
    let validationErrors = formState.validationErrors;
    e.forEach((fields: any) => {
      const field = fields.field;
      switch (field) {
        case "lastNameKanji":
          validationErrors.lastNameKanji = true;
          break;
        case "firstNameKanji":
          validationErrors.firstNameKanji = true;
          break;
        case "lastNameKana":
          validationErrors.lastNameKana = true;
          break;
        case "firstNameKana":
          validationErrors.firstNameKana = true;
          break;
        case "birthday":
          validationErrors.birthdayYear = true;
          validationErrors.birthdayMonth = true;
          validationErrors.birthdayDay = true;
          break;
        case "phoneNumber":
          validationErrors.phoneNumber = true;
          break;
        case "phoneType":
          validationErrors.phoneType = true;
          break;
      }
    });
    setFormState((prevState) => ({ ...prevState, validationErrors }));
  };

  // 氏名、フリカナを処理
  const handleNameInputChange = (e: any) => {
    const key = e.target.id;
    let validationErrors = formState.validationErrors;
    if (key === "first_name") {
      validationErrors.firstNameKanji = false;
      setFormState((prevState) => ({
        ...prevState,
        first_name: e.target.value,
        validationErrors,
      }));
      if (e.target.value) {
        setInputFlag((prevState) => ({ ...prevState, firstName: true }));
      } else {
        setInputFlag((prevState) => ({ ...prevState, firstName: false }));
      }
    }
    if (key === "last_name") {
      validationErrors.lastNameKanji = false;
      setFormState((prevState) => ({
        ...prevState,
        last_name: e.target.value,
        validationErrors,
      }));
      if (e.target.value) {
        setInputFlag((prevState) => ({ ...prevState, lastName: true }));
      } else {
        setInputFlag((prevState) => ({ ...prevState, lastName: false }));
      }
    }
    if (key === "firstNameFurigana") {
      validationErrors.firstNameKana = false;
      setFormState((prevState) => ({
        ...prevState,
        firstNameFurigana: e.target.value,
        validationErrors,
      }));
      if (e.target.value) {
        setInputFlag((prevState) => ({
          ...prevState,
          firstNameFurigana: true,
        }));
      } else {
        setInputFlag((prevState) => ({
          ...prevState,
          firstNameFurigana: false,
        }));
      }
    }
    if (key === "lastNameFurigana") {
      validationErrors.lastNameKana = false;
      setFormState((prevState) => ({
        ...prevState,
        lastNameFurigana: e.target.value,
        validationErrors,
      }));
      if (e.target.value) {
        setInputFlag((prevState) => ({ ...prevState, lastNameFurigana: true }));
      } else {
        setInputFlag((prevState) => ({
          ...prevState,
          lastNameFurigana: false,
        }));
      }
    }
  };
  // 姓名仕様処理
  const handleLastNameInputStyleChange = () => {
    let className = "ReservationForm-input";
    if (noAvailableOperator) {
      className = "ReservationForm-input-disabled";
    } else if (formState.validationErrors.lastNameKanji) {
      className = "ReservationForm-input-error";
    } else {
      if (formState.last_name.length) {
        className = "ReservationForm-input-value";
      } else {
        className = "ReservationForm-input";
      }
    }
    return className;
  };
  const handleFristNameInputStyleChange = () => {
    let className = "ReservationForm-input";
    if (noAvailableOperator) {
      className = "ReservationForm-input-disabled";
    } else if (formState.validationErrors.firstNameKanji) {
      className = "ReservationForm-input-error";
    } else {
      if (formState.first_name.length) {
        className = "ReservationForm-input-value";
      } else {
        className = "ReservationForm-input";
      }
    }
    return className;
  };

  useEffect(() => {
    // Uncomment when birthday CSS issue is completed
    if (
      !formState.validationErrors.firstNameKanji &&
      !formState.validationErrors.birthdayYear &&
      !formState.validationErrors.birthdayMonth &&
      !formState.validationErrors.birthdayDay &&
      !formState.validationErrors.firstNameKana &&
      !formState.validationErrors.lastNameKana &&
      !formState.validationErrors.lastNameKanji &&
      !formState.validationErrors.phoneNumber
    ) {
      setFormState((prevState) => ({
        ...prevState,
        hasValidationErrors: false,
        validationErrMsg: "",
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        hasValidationErrors: true,
        validationErrMsg:
          "入力内容に誤りがあります。以下の項目を訂正し、「同意して入力内容を確認」ボタンを押してください。",
      }));
    }
    // eslint-disable-next-line
  }, [
    formState.validationErrors.firstNameKanji,
    formState.validationErrors.birthdayYear,
    formState.validationErrors.birthdayMonth,
    formState.validationErrors.birthdayDay,
    formState.validationErrors.firstNameKana,
    formState.validationErrors.lastNameKana,
    formState.validationErrors.lastNameKanji,
    formState.validationErrors.phoneNumber,
  ]);

  useEffect(() => {
    if (formState.TermsAndConditionModal) {
      setTimeout(() => {
        addScrollControl();
      }, 200);
    } else {
      removeScrollControl();
    }
  }, [formState.TermsAndConditionModal]);

  const scrollControl = (e: any) => {
    if (!modalRef?.current) return;
    const modal = modalRef.current as HTMLElement;
    if (!modal.contains(e.target)) {
      e.preventDefault();
    }
    if (!modalRef?.current) return;
    const isScrollingDown =
      e.type === "mousewheel"
        ? e.wheelDelta < 0
        : touchStartY > e.changedTouches[0].pageY;
    if (isScrollingDown) {
      const modal = modalRef.current as HTMLElement;
      const isAtBottom =
        modal.scrollTop + modal.clientHeight >= modal.scrollHeight;
      if (isAtBottom && e.cancelable) {
        e.preventDefault();
      }
    } else {
      const modal = modalRef.current as HTMLElement;
      const isAtTop = modal.scrollTop === 0;
      if (isAtTop && e.cancelable) {
        e.preventDefault();
      }
    }
  };

  const addScrollControl = () => {
    document.addEventListener(
      "touchstart",
      (e: any) => {
        touchStartY = e.touches[0].pageY;
      },
      false
    );
    document.addEventListener("mousewheel", scrollControl, {
      passive: false,
    });
    document.addEventListener("touchmove", scrollControl, {
      passive: false,
    });
  };

  const removeScrollControl = () => {
    document.removeEventListener("mousewheel", scrollControl, false);
    document.removeEventListener("touchmove", scrollControl, false);
  };

  // フリカナ仕様処理
  const handleLastNameKanaInputStyleChange = () => {
    let className = "ReservationForm-input";
    if (noAvailableOperator) {
      className = "ReservationForm-input-disabled";
    } else if (formState.validationErrors.lastNameKana) {
      className = "ReservationForm-input-error";
    } else {
      if (formState.lastNameFurigana.length) {
        className = "ReservationForm-input-value";
      } else {
        className = "ReservationForm-input";
      }
    }
    return className;
  };
  const handleFristNameKanaInputStyleChange = () => {
    let className = "ReservationForm-input";
    if (noAvailableOperator) {
      className = "ReservationForm-input-disabled";
    } else if (formState.validationErrors.firstNameKana) {
      className = "ReservationForm-input-error";
    } else {
      if (formState.firstNameFurigana.length) {
        className = "ReservationForm-input-value";
      } else {
        className = "ReservationForm-input";
      }
    }
    return className;
  };
  // 携帯電話の処理
  const handleNumberInputChange = (e: any) => {
    const key = e.target.id;
    const inputValue = e.target.value;
    let validationErrors = formState.validationErrors;
    validationErrors.phoneNumber = false;
    let errors = formState.errors;
    if (key === "phoneNumber") {
      setFormState((prevState) => ({
        ...prevState,
        errors,
        phoneNumber: inputValue,
        validationErrors,
      }));
    }
  };
  const handlePhoneStyleChange = () => {
    let className = "ReservationForm-input-moblie";
    if (noAvailableOperator) {
      className = "ReservationForm-input-moblie-disabled";
    } else if (formState.validationErrors.phoneNumber) {
      className = "ReservationForm-input-moblie-error";
    } else {
      if (formState.phoneNumber.length) {
        className = "ReservationForm-input-moblie-value";
      } else {
        className = "ReservationForm-input-moblie";
      }
    }
    return className;
  };
  const handlePhoneTypeStyleChange = () => {
    let className = "ReservationForm-radioButton";
    if (formState.validationErrors.phoneType) {
      className = "ReservationForm-radioButton-error";
    } else {
      if (formState.phoneType.length) {
        className = "ReservationForm-radioButton-value";
      } else {
        className = "ReservationForm-radioButton";
      }
    }
    return className;
  };

  const handlePhoneTypeChange = (e: any) => {
    let validationErrors = formState.validationErrors;
    validationErrors.phoneType = false;
    const inputValue = e.target.value;
    setFormState((prevState) => ({
      ...prevState,
      phoneType: inputValue,
      validationErrors,
    }));
  };

  const topFunction = () => {
    window.scrollTo(0, 0);
  };

  // 確認ボタン処理
  const handleSubmitForm = (): void => {
    const clickEventHandling = inputFlag.clickEventHandling;
    setInputFlag((prevState) => ({
      ...prevState,
      clickEventHandling: true,
    }));
    setTimeout(() => {
      if (clickEventHandling) return;
      let count = formState.phoneNumber?.length;
      let firstThreeValue = formState.phoneNumber.substring(0, 3);
      if (
        formState.first_name.length > 0 &&
        formState.last_name.length > 0 &&
        formState.firstNameFurigana.length > 0 &&
        formState.lastNameFurigana.length > 0 &&
        checkDate() &&
        formState.firstSeletY &&
        formState.firstSeletM &&
        formState.firstSeletD &&
        checkAgeIsInRange() &&
        checkFristName() &&
        checkLastName() &&
        checkFuriganaFristName() &&
        checkFuriganaLastName() &&
        count === 11 &&
        firstThreeValue &&
        reg.test(formState.phoneNumber) &&
        (firstThreeValue === firstNum ||
          firstThreeValue === secondNum ||
          firstThreeValue === thirdNum) &&
        formState.phoneType.length > 0
      ) {
        holder.history.push({
          pathname: "/review",
          state: {
            ...formState,
            first_name: formState.first_name,
            last_name: formState.last_name,
            firstNameFurigana: formState.firstNameFurigana,
            lastNameFurigana: formState.lastNameFurigana,
            phoneNumber: formState.phoneNumber,
            phoneType: formState.phoneType,
            birthdayYear: formState.birthdayYear,
            birthdayMonth: formState.birthdayMonth,
            birthdayDay: formState.birthdayDay,
            firstSeletY: formState.firstSeletY,
            firstSeletM: formState.firstSeletM,
            firstSeletD: formState.firstSeletD,
            selectYearFlag: formState.selectYearFlag,
            selectMonthFlag: formState.selectMonthFlag,
            selectDayFlag: formState.selectDayFlag,
            availableAgents: formState.availableAgents,
            inPreparingAgents: formState.inPreparingAgents,
          },
        });
      } else {
        let validationErrors = formState.validationErrors;
        let errors = formState.errors;
        errors.errorLastName = false;
        errors.errorFirstName = false;
        errors.errorFuriganaLastName = false;
        errors.errorFuriganaFirstName = false;
        errors.errNumMess = false;
        setFormState((prevState) => ({
          ...prevState,
          hasValidationErrors: true,
          validationErrMsg:
            "入力内容に誤りがあります。以下の項目を訂正し、「同意して入力内容を確認」ボタンを押してください。",
        }));
        window.scrollTo(0, 0);
        if (formState.last_name.length === 0 || !checkLastName()) {
          validationErrors.lastNameKanji = true;
        }
        if (formState.first_name.length === 0 || !checkFristName()) {
          validationErrors.firstNameKanji = true;
        }
        if (
          formState.lastNameFurigana.length === 0 ||
          !checkFuriganaLastName()
        ) {
          validationErrors.lastNameKana = true;
        }
        if (
          formState.firstNameFurigana.length === 0 ||
          !checkFuriganaFristName()
        ) {
          validationErrors.firstNameKana = true;
        }
        if (
          !formState.birthdayYear ||
          !formState.firstSeletY ||
          !checkAgeIsInRange()
        ) {
          validationErrors.birthdayYear = true;
        }
        if (
          !formState.birthdayMonth ||
          !formState.firstSeletM ||
          !checkAgeIsInRange()
        ) {
          validationErrors.birthdayMonth = true;
        }
        if (
          !formState.birthdayDay ||
          !formState.firstSeletD ||
          !checkAgeIsInRange()
        ) {
          validationErrors.birthdayDay = true;
        }
        if (
          count !== 11 ||
          !reg.test(formState.phoneNumber) ||
          !(
            firstThreeValue === firstNum ||
            firstThreeValue === secondNum ||
            firstThreeValue === thirdNum
          )
        ) {
          validationErrors.phoneNumber = true;
        }
        if (formState.phoneType.length === 0) {
          validationErrors.phoneType = true;
        }
        setFormState((prevState) => ({ ...prevState, validationErrors }));
      }
      setInputFlag((prevState) => ({
        ...prevState,
        clickEventHandling: false,
      }));
    }, 1000);
  };

  const openTermsAndConditionModal = () => {
    setFormState((prevState) => ({
      ...prevState,
      TermsAndConditionCloseModal: !formState.TermsAndConditionModal,
      TermsAndConditionModal: !formState.TermsAndConditionModal,
    }));
  };

  // 生年月日作成
  const getDatas = (e: any) => {
    let validationErrors = formState.validationErrors;
    if (e.selectYear) {
      validationErrors.birthdayYear = false;
    }
    if (e.selectMonth) {
      validationErrors.birthdayMonth = false;
    }
    if (e.selectDay) {
      validationErrors.birthdayDay = false;
    }
    formState.birthday = e.selectYear + e.selectMonth + e.selectDay;
    setFormState((prevState) => ({
      ...prevState,
      birthday: formState.birthday,
      birthdayYear: e.selectYear,
      birthdayMonth: e.selectMonth,
      birthdayDay: e.selectDay,
      firstSeletY: e.firstSeletY,
      firstSeletM: e.firstSeletM,
      firstSeletD: e.firstSeletD,
      selectYearFlag: e.selectYearFlag,
      selectMonthFlag: e.selectMonthFlag,
      selectDayFlag: e.selectDayFlag,
      validationErrors,
    }));
  };

  // 修正から取得前ページのデータ
  const person = {
    selectYear: holder.location.state?.birthdayYear,
    selectMonth: holder.location.state?.birthdayMonth,
    selectDay: holder.location.state?.birthdayDay,
    disableFlag: noAvailableOperator ? true : false,
    errorFlagY: formState.validationErrors.birthdayYear,
    errorFlagM: formState.validationErrors.birthdayMonth,
    errorFlagD: formState.validationErrors.birthdayDay,
    firstSeletY: holder.location.state?.firstSeletY,
    firstSeletM: holder.location.state?.firstSeletM,
    firstSeletD: holder.location.state?.firstSeletD,
    selectYearFlag: holder.location.state?.selectYearFlag,
    selectMonthFlag: holder.location.state?.selectMonthFlag,
    selectDayFlag: holder.location.state?.selectDayFlag,
  };

  // StepBar設定
  const steps = {
    step: 0,
  };

  // 日時入力チェック
  const checkDate = () => {
    if (
      formState.birthdayYear === "" ||
      formState.birthdayMonth === "" ||
      formState.birthdayDay === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  //年齢チャック（18~70）
  const checkAgeIsInRange = () => {
    const now = new Date();
    const youngestBirthday = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const oldestBirthday = new Date(
      now.getFullYear() - 75,
      now.getMonth(),
      now.getDate()
    );
    const birthday = new Date(
      parseInt(formState.birthdayYear.substring(0, 4)),
      parseInt(formState.birthdayMonth) - 1,
      parseInt(formState.birthdayDay)
    );
    return birthday <= youngestBirthday && birthday > oldestBirthday;
  };

  // 入力チェック
  const checkFristName = () => {
    let firstName = formState.first_name;
    return TextUtils.isZenkakuJIS12(firstName);
  };
  const checkLastName = () => {
    let lastName = formState.last_name;
    return TextUtils.isZenkakuJIS12(lastName);
  };
  const checkFuriganaFristName = () => {
    let firstNameFurigana = formState.firstNameFurigana;
    return TextUtils.fullWidthKana(firstNameFurigana);
  };
  const checkFuriganaLastName = () => {
    let lastNameFurigana = formState.lastNameFurigana;
    return TextUtils.fullWidthKana(lastNameFurigana);
  };

  const handleReturnForm = (): void => {
    const clickEventHandling = inputFlag.clickEventHandling;
    setInputFlag((prevState) => ({
      ...prevState,
      clickEventHandling: true,
    }));
    setTimeout(() => {
      if (clickEventHandling) return;
      window.location.href = onlineInstruction!.toString();
      setInputFlag((prevState) => ({
        ...prevState,
        clickEventHandling: false,
      }));
    }, 1000);
  };

  // radio作成
  const PromiseRadio = withStyles({
    root: {
      color: "#00479D",
      opacity: 0.5,
      "&$checked": {
        color: "#004199",
        opacity: 1,
      },
    },
    checked: {},
  })((props: any) => <Radio color="default" {...props} />);

  // onBlur チェック
  const inputOnBlur = (e: any) => {
    const key = e.target.id;
    let errors = formState.errors;
    e.target.value = TextUtils.trimBoth(e.target.value);
    if (key === "last_name") {
      if (!e.target.value) {
        errors.errorLastName = true;
        errors.errorLastNameMess = "氏名 姓を入力してください。";
      } else if (!TextUtils.isZenkakuJIS12(e.target.value)) {
        errors.errorLastName = true;
        errors.errorLastNameMess = "氏名 姓は全角で入力してください。";
      } else {
        errors.errorLastName = false;
      }
    } else if (key === "first_name") {
      if (!e.target.value) {
        errors.errorFirstName = true;
        errors.errorFirstNameMess = "氏名 名を入力してください。";
      } else if (!TextUtils.isZenkakuJIS12(e.target.value)) {
        errors.errorFirstName = true;
        errors.errorFirstNameMess = "氏名 名は全角で入力してください。";
      } else {
        errors.errorFirstName = false;
      }
    } else if (key === "lastNameFurigana") {
      e.target.value = TextUtils.toKatakana(e.target.value);
      setFormState((prevState) => ({
        ...prevState,
        errors,
        lastNameFurigana: e.target.value,
      }));
      if (!e.target.value) {
        errors.errorFuriganaLastName = true;
        errors.errorFuriganaLastNameMess = "フリガナ セイを入力してください。";
      } else if (!TextUtils.fullWidthKana(e.target.value)) {
        errors.errorFuriganaLastName = true;
        errors.errorFuriganaLastNameMess =
          "フリガナ セイは全角カナで入力してください。";
      } else {
        errors.errorFuriganaLastName = false;
      }
    } else if (key === "firstNameFurigana") {
      e.target.value = TextUtils.toKatakana(e.target.value);
      setFormState((prevState) => ({
        ...prevState,
        errors,
        firstNameFurigana: e.target.value,
      }));
      if (!e.target.value) {
        errors.errorFuriganaFirstName = true;
        errors.errorFuriganaFirstNameMess = "フリガナ メイを入力してください。";
      } else if (!TextUtils.fullWidthKana(e.target.value)) {
        errors.errorFuriganaFirstName = true;
        errors.errorFuriganaFirstNameMess =
          "フリガナ メイは全角カナで入力してください。";
      } else {
        errors.errorFuriganaFirstName = false;
      }
    } else if (key === "phoneNumber") {
      let count = formState.phoneNumber?.length;
      let firstThreeValue = formState.phoneNumber.substring(0, 3);
      if (!reg.test(formState.phoneNumber)) {
        errors.errNumMess = true;
        errors.errNumCharMess = "電話番号は半角整数で入力してください。";
      } else if (
        !(
          firstThreeValue === firstNum ||
          firstThreeValue === secondNum ||
          firstThreeValue === thirdNum
        )
      ) {
        errors.errNumMess = true;
        errors.errNumCharMess =
          "090,080,070のいずれかから始まる電話番号を入力してください。";
      } else if (count !== 11) {
        errors.errNumMess = true;
        errors.errNumCharMess = "電話番号を11桁で入力してください。";
      } else {
        errors.errNumMess = false;
      }
    }
    setFormState((prevState) => ({ ...prevState, errors }));
  };

  return noAvailableOperator ? (
    <NoAvailableOperator />
  ) : (
    <Translation>
      {(t, { i18n }) => (
        <Grid>
          <Grid className="ReservationForm-WholeForm">
            <div className="ReservationForm-Title">オンライン相談のお申込</div>
          </Grid>
          {!noAvailableOperator && <StepBar {...steps}></StepBar>}

          {noAvailableOperator && <NoAvailableOperator />}
          {noAvailableOperator ? (
            <div className="ReservationForm-form">
              <button
                className="ReservationForm-returnButton"
                onClick={() => handleReturnForm()}
              >
                戻る
              </button>
              <button
                onClick={() => topFunction()}
                className="ReservationForm-backToTopButton"
              >
                <ArrowUpwardIcon />
                このページのTOPへ戻る
              </button>
            </div>
          ) : (
            <div className="ReservationForm-form">
              {agentsInPreparing && <NeedToWait />}
              {formState.hasValidationErrors ? (
                <div className="ReservationForm-errorCard">
                  <div className="ReservationForm-errorMessageCard">
                    {formState.errors.errorMessage ||
                      formState.validationErrMsg}
                  </div>
                </div>
              ) : null}
              <div
                className="ReservationForm-label"
                style={{
                  marginTop: 50,
                }}
              >
                氏名
              </div>
              <Grid>
                <div className="ReservationForm-form-input">
                  <div className="ReservationForm-div">
                    <input
                      disabled={noAvailableOperator}
                      onKeyDown={characterOnly}
                      id="last_name"
                      type="text"
                      maxLength={20}
                      defaultValue={formState.last_name}
                      placeholder="約束"
                      onChange={handleNameInputChange}
                      className={handleLastNameInputStyleChange()}
                      autoComplete="off"
                      onBlur={inputOnBlur}
                    ></input>
                    <input
                      disabled={noAvailableOperator}
                      onKeyDown={characterOnly}
                      id="first_name"
                      type="text"
                      maxLength={20}
                      defaultValue={formState.first_name}
                      onChange={handleNameInputChange}
                      placeholder="太郎"
                      className={handleFristNameInputStyleChange()}
                      autoComplete="off"
                      onBlur={inputOnBlur}
                    ></input>
                  </div>
                </div>
                {formState.errors.errorLastName ? (
                  <span className="ReservationForm-errorMessage">
                    <div>{formState.errors.errorLastNameMess}</div>
                  </span>
                ) : null}
                {formState.errors.errorFirstName ? (
                  <span className="ReservationForm-errorMessage">
                    <div>{formState.errors.errorFirstNameMess}</div>
                  </span>
                ) : null}
                <div className="ReservationForm-label">フリガナ</div>
                <div className="ReservationForm-form-input">
                  <div className="ReservationForm-div">
                    <input
                      disabled={noAvailableOperator}
                      onKeyDown={characterOnly}
                      id="lastNameFurigana"
                      type="text"
                      maxLength={20}
                      defaultValue={formState.lastNameFurigana}
                      placeholder="ヤクソク"
                      onChange={handleNameInputChange}
                      className={handleLastNameKanaInputStyleChange()}
                      autoComplete="off"
                      onBlur={inputOnBlur}
                    ></input>
                    <input
                      disabled={noAvailableOperator}
                      onKeyDown={characterOnly}
                      id="firstNameFurigana"
                      type="text"
                      maxLength={20}
                      defaultValue={formState.firstNameFurigana}
                      placeholder="タロウ"
                      onChange={handleNameInputChange}
                      className={handleFristNameKanaInputStyleChange()}
                      autoComplete="off"
                      onBlur={inputOnBlur}
                    ></input>
                  </div>
                </div>
                {formState.errors.errorFuriganaLastName ? (
                  <span className="ReservationForm-errorMessage">
                    <div>{formState.errors.errorFuriganaLastNameMess}</div>
                  </span>
                ) : null}

                {formState.errors.errorFuriganaFirstName ? (
                  <span className="ReservationForm-errorMessage">
                    <div>{formState.errors.errorFuriganaFirstNameMess}</div>
                  </span>
                ) : null}
              </Grid>
              <div className="ReservationForm-label">生年月日</div>
              <DateSelect {...person} getdata={getDatas}></DateSelect>
              <div className="ReservationForm-form-input">
                <div className="ReservationForm-label">携帯電話番号</div>
              </div>
              <div>
                <input
                  disabled={noAvailableOperator}
                  id="phoneNumber"
                  type="tel"
                  maxLength={11}
                  defaultValue={formState.phoneNumber}
                  placeholder="09012345678"
                  onChange={handleNumberInputChange}
                  className={handlePhoneStyleChange()}
                  onBlur={inputOnBlur}
                ></input>
              </div>
              <div className="ReservationForm-reminder">
                ※招待用URLはSMSにてお送りいたします。入力間違いにご注意ください。
              </div>
              {formState.errors.errNumMess ? (
                <div className="ReservationForm-errorMessage">
                  {formState.errors.errNumCharMess}
                </div>
              ) : (
                <div>
                  <p className="p-errorMessage">
                    {formState.errors.errorNumber}
                  </p>
                  <p className="p-errorMessage">
                    {formState.errors.mobileNumber}
                  </p>
                </div>
              )}

              <FormControl
                style={{
                  width: "100%",
                }}
              >
                <div className="ReservationForm-label">携帯電話の種類</div>
                <RadioGroup
                  value={formState.phoneType}
                  onChange={handlePhoneTypeChange}
                  row
                  className="ReservationForm-form-radio"
                >
                  <FormControlLabel
                    disabled={noAvailableOperator}
                    className={handlePhoneTypeStyleChange()}
                    value="iPhone"
                    control={<PromiseRadio />}
                    label={
                      <span className="ReservationForm-radioButtonName">
                        iPhone
                      </span>
                    }
                    style={{
                      margin: 0,
                    }}
                  />
                  <FormControlLabel
                    disabled={noAvailableOperator}
                    className={handlePhoneTypeStyleChange()}
                    value="Android"
                    control={<PromiseRadio />}
                    label={
                      <span className="ReservationForm-radioButtonName">
                        Android
                      </span>
                    }
                    style={{
                      margin: 0,
                    }}
                  />
                  <FormControlLabel
                    disabled={noAvailableOperator}
                    className={handlePhoneTypeStyleChange()}
                    value="その他"
                    control={<PromiseRadio />}
                    label={
                      <span className="ReservationForm-radioButtonName">
                        その他
                      </span>
                    }
                    style={{
                      margin: 0,
                    }}
                  />
                </RadioGroup>
              </FormControl>

              <Grid>
                <div className="ReservationForm-agreementText">同意事項</div>
                <div className="ReservationForm-checkAgreementText">
                  お申込にあたり、以下の内容をご確認ください。
                </div>
                <label onClick={() => openTermsAndConditionModal()}>
                  <a className="ReservationForm-agreementLink">
                    オンライン相談利用規約
                  </a>
                  <img
                    alt="img"
                    className="ReservationForm-popOutIcon"
                    src={popOutIcon}
                  />
                </label>
                <button
                  className="ReservationForm-submitButton"
                  onClick={() => handleSubmitForm()}
                >
                  同意して入力内容を確認
                  <ArrowForwardIosIcon />
                </button>
                <button
                  className="ReservationForm-returnButton"
                  onClick={() => handleReturnForm()}
                >
                  戻る
                </button>
                <button
                  onClick={() => topFunction()}
                  className="ReservationForm-backToTopButton"
                >
                  <ArrowUpwardIcon />
                  このページのTOPへ戻る
                </button>
              </Grid>
            </div>
          )}
          {formState.TermsAndConditionModal ? (
            <TermsAndConditionModal
              modalvisible={() => openTermsAndConditionModal()}
              closeSetState={() => {
                setFormState((prevState) => ({
                  ...prevState,
                  TermsAndConditionCloseModal: false,
                  TermsAndConditionModal: false,
                }));
              }}
              getRef={(ref: any) => {
                modalRef = ref;
              }}
            />
          ) : null}
        </Grid>
      )}
    </Translation>
  );
};

export default ReservationForm;
