import React, { createRef } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Grid, Card, Modal } from "@material-ui/core";

import TermsAndConditionModalStates from "../../../../interfaces/TermsAndConditionModal";
import "./styles.css";

interface Props extends RouteComponentProps<any> {
  modalvisible: any;
  closeSetState: Function;
  getRef: Function;
}

class TermsAndConditionModal extends React.Component<
  Props,
  TermsAndConditionModalStates
> {
  state: TermsAndConditionModalStates = {
    modalVisible: true,
  };
  ref = createRef<any>();
  componentDidMount() {
    this.props.getRef(this.ref);
  }

  handleCloseTermsAndConditionModal = () => {
    this.setState({ modalVisible: false });
    this.props.modalvisible(!this.state.modalVisible);
  };

  render() {
    return (
      <Grid container>
        <Modal open={this.state.modalVisible}>
          <Card className="Reservation-TermsAndCondition-Card">
            <div
              className="Reservation-TermsAndCondition-close"
              onClick={() => {
                this.handleCloseTermsAndConditionModal();
              }}
            >
              ×
            </div>
            <div className="modalDiv" ref={this.ref}>
              <div className="Reservation-TermsAndCondition-title">
                オンライン相談利用規約{" "}
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第１条（目的）
                <br />
                <div className="div-left">
                  <div className="P-left">１． </div>
                  <div>
                    本規約は、SMBCコンシューマーファイナンス株式会社（以下「当社」といいます）が提供するオンライン相談サービス（以下「本サービス」といいます）の運営に関する基本的事項について定めるものです。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">２． </div>
                  <div>
                    本サービスは、本規約を承認のうえ当社が定める所定の手続きを行うことにより、利用することができます。
                  </div>
                </div>
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第２条（サービス内容・範囲）
                <br />
                <div className="div-left">
                  <div className="P-left">１． </div>
                  <div>
                    本サービスは、はじめてのお客さまが安心してプロミスをご利用いただけるよう、アバター（※）にお借入に関するご相談やご質問をすることができるサービスです。本サービスは日本語のみの対応となります。
                    <br />※ アバターを介して当社の社員がご相談を承ります。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">２． </div>
                  <div>
                    本サービスは、はじめてのお客さまへの提供を前提としており、サービスの範囲は以下のとおりです。
                    <br />
                    （１） お申込前のご相談・ご質問
                    <br />
                    （２） お申込手続きのサポート（本人確認書類提出まで）
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">３． </div>
                  <div>
                    本サービスの提供は、キャッシングなど当社のサービスの提供を確約するものではありません。
                  </div>
                </div>
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第３条（規約の改定）
                <br />
                <div className="div-left">
                  <div className="P-left">１． </div>
                  <div>
                    当社は、次に掲げる場合には、次項に定める方法により、本規約を変更することができます。
                    <br />
                    （1）変更内容がお客さまの一般の利益に適合するとき。
                    <br />
                    （2）変更内容が本規約に係る取引の目的に反せず、変更の必要性、変更内容の相当性その他変更に係る事情に照らして合理的なものであるとき。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">２． </div>
                  <div>
                    当社は、前項の各事由に基づき本規約を変更するときは、本規約を変更する旨、変更内容および効力発生時期を、当社ホームページにおいて（前項第2号の場合についてはあらかじめ）公表するほか、必要があるときにはその他相当な方法で周知するものとします。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">３． </div>
                  <div>
                    本条に定めるほか、当社は、変更内容をお客さまに通知または当社が相当と認める方法により公告する方法により、本規約を変更することができます。この場合には、お客さまは、当該通知または公告の後に本規約に基づく取引を行うことにより、変更内容に対する承諾の意思表示を行うものとし当該意思表示をもって本規約が変更されます。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">４． </div>
                  <div>
                    本条に定めるほか、当社は、本規約の軽微な変更については、変更内容を当社ホームページにおいて公表する方法によりすることができるものとします。この場合には、当社は、当該通知または公告がされた後、30日が経過したことをもって、お客さまがその変更内容を承認したものとみなします。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">５． </div>
                  <div>
                    前各項に基づく本規約の変更に異議があるお客さまは、使用期間中であっても使用を終了させることができます。
                  </div>
                </div>
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第４条（個人情報の取り扱い）
                <br />
                <div className="div-left">
                  <div className="P-left">１． </div>
                  <div>
                    当社は、お客さまから聴取した個人情報を当社のホームページに公表する「個人情報保護方針」ならびに「個人情報保護の取扱い」に基づいて、取り扱います。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">２． </div>
                  <div>
                    本サービスの運営のために取得した個人情報は、本サービスの運営のほか、「個人情報の取扱いについて」第11条第1項に定める目的で個人を特定しない統計情報に加工して利用いたします。
                  </div>
                </div>
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第５条（サービスの中断及び終了）
                <br />
                <div className="div-left">
                  <div className="P-left">１． </div>
                  <div>
                    当社は次の各号に該当する場合に、本サービスを中断または終了することができるものとします。
                    <br />
                    （１） お客さまのオンライン相談のお申込内容に誤りがある場合
                    <br />
                    （２）
                    招待用URL記載のSMS送信後、10分以内にお客さまの入室がない場合
                    <br />
                    （３）
                    当社または当社従業員等に対する誹謗中傷等、本サービスの利用目的に合致しないと当社が判断した場合
                    <br />
                    （４）
                    電話、メールなど、本サービス以外の方法により対応することが適切であると当社が判断した場合
                    <br />
                    （５）
                    本サービスによる対応を継続するにふさわしくないと当社が判断した場合
                    <br />
                    （６）
                    本サービスの提供に必要な保守・点検を行う場合、または通信等の障害で本サービスの提供が不能となった場合
                    <br />
                    （７）
                    前各号に定めるほか当社が本サービスの中断または終了の必要があると判断した場合
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">２． </div>
                  <div>
                    当社は、本サービスを終了するときは、ホームページ等所定の方法で事前に告知するものとします。ただし、お客様の損害発生を防止するためなど、直ちに本サービスを終了する必要がある場合には、この限りではありません。
                  </div>
                </div>
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第６条（免責事項）
                <br />
                <div className="div-left">
                  <div className="P-left">　　 </div>
                  <div>
                    当社は、次の各号によってお客さまに生じた損害について責任を負いません。ただし、当社の故意または過失による事由の場合はその限りではありません。
                    <br />
                    （１）本サービスご利用中に生じた不具合等
                    <br />
                    （２）本サービス提供の遅延または不能
                    <br />
                    （３）当社が送信した情報の誤り
                  </div>
                </div>
              </div>
              <div className="Reservation-TermsAndCondition-msg-content">
                第７条（準拠法・合意管轄裁判所）
                <br />
                <div className="div-left">
                  <div className="P-left">１． </div>
                  <div>
                    本規約は、日本法に準拠し同法によって解釈されるものとします。
                  </div>
                </div>
                <div className="div-left">
                  <div className="P-left">２． </div>
                  <div>
                    本サービスに関して訴訟の必要が生じた場合、当社の本社を管轄する地方裁判所または簡易裁判所を管轄裁判所とすることに合意します。
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Modal>
      </Grid>
    );
  }
}

export default withRouter(TermsAndConditionModal);
