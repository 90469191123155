/**
 * テキスト編集用Utils
 *
 * @export
 * @class TextUtils
 */

interface ZenKatakanaCharCode {
  nextIter: number;
  charCode: number;
}
export class TextUtils {
  /**
   * 入力欄が空白かどうかを判断する
   *
   * @static
   * @param {string} value
   * @return {*}  {boolean}
   * @memberof TextUtils
   */
  public static isEmptyInputValue(value: string): boolean {
    return value == null || value.length === 0;
  }

  /**
   * 指定された文字列がchar文字に変換されるかどうかを確認する。
   *
   * @private
   * @static
   * @param {String} s
   * @return {*}  {boolean}
   * @memberof TextUtils
   */
  private static checkReplacementChar(s: string): boolean {
    if (TextUtils.isEmptyInputValue(s)) {
      return true;
    }
    for (let i = 0; i < s.length; i++) {
      const c: number = s.charCodeAt(i);
      if (c === 0xfffd) {
        return true;
      }
    }
    return false;
  }

  /**
   * 全角カナ
   *
   * @static
   * @param {FormControl} control
   * @returns {*}
   * @memberof StringValidator
   */
  public static fullWidthKana(str: string): boolean {
    if (TextUtils.isEmptyInputValue(str)) {
      return true;
    }
    for (let iter = 0; iter < str.length; iter = iter + 1) {
      const c: number = str.charCodeAt(iter);
      if ((c < 0x30a1 || c > 0x30f6) && c !== 0x30fc) {
        return false;
      }
    }
    return true;
  }

  /**
   * 渡された文字列が全角文字のみで構成され、かつ含まれる漢字がJIS第一水準、第二水準の範囲内であるかどうか判定する。
   * 01〜08区 各種記号、英数字、かな SJIS(0x8140-0x84BE)
   * 16〜47区 第一水準漢字 SJIS(0x889F-0x9872)
   * 48〜84区 第二水準漢字 SJIS(0x989F-0x9FFC, 0xE040-0xEAA4)
   *
   * @static
   * @param {string} str
   * @return {*}  {boolean}
   * @memberof TextUtils
   */
  public static isZenkakuJIS12(str: string): boolean {
    const MojiJS = require("mojijs").default;
    if (TextUtils.isEmptyInputValue(str)) {
      return true;
    }

    if (TextUtils.checkReplacementChar(str)) {
      return false;
    }

    for (var i = 0; i < str.length; i++) {
      const c = str.charAt(i);
      const strData = MojiJS.getMojiData(MojiJS.codePointAt(c));
      const byteText = strData.encode.cp932_array[0];
      // NEC特殊文字 13区(0x8740 - 0x879E)の特殊文字
      // NEC選定IBM拡張文字：89~92区（0xed40～0xeefc）の特殊文字
      // IBM拡張文字：115区~119区(0xfa40～0xfc4b)の特殊文字
      if (
        (byteText >= 0x87 && byteText <= 0x9e) ||
        (byteText >= 0xed && byteText <= 0xfc) ||
        (byteText >= 0xfa && byteText <= 0x4b)
      ) {
        continue;
      }
      if (strData.type.is_regular_sjis && strData.encode.kuten) {
        const ku = strData.encode.kuten.ku;
        if ((ku > 8 && ku < 16) || ku > 84) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  }

  /**
   * 半角カタカナ、全角ひらがなを全角カタカナに変換する。(Kata+ZenKata)
   *い
   * @param s 処理対象文字列
   * @return 変換した文字列
   */
  public static toKatakana(s: string): string {
    if (!s || s.length <= 0) {
      return s;
    }
    let result: string = "";

    for (let iter = 0; iter < s.length; iter = iter + 1) {
      const c: number = s.charCodeAt(iter);
      const zenKatakanaCharCode = TextUtils.toZenKatakanaChar(c, s, iter);
      result += String.fromCharCode(zenKatakanaCharCode.charCode);
      iter = zenKatakanaCharCode.nextIter;
    }
    return TextUtils.zenHiraToZenKata(result);
  }

  /**
   * 半角カタカナを全角カタカナに変換する。
   *
   * @private
   * @param {number} c
   * @param {number} iter
   * @return {*}  {number}
   * @memberof TextUtils
   */
  private static toZenKatakanaChar(
    c: number,
    s: string,
    iter: number
  ): ZenKatakanaCharCode {
    if (TextUtils.isHanKatakana(c)) {
      iter = iter + 1;
      const next: number = s.charCodeAt(iter);
      if (
        next === s.charCodeAt(s.length - 1) ||
        !(next === 0xff9e || next === 0xff9f)
      ) {
        return {
          nextIter: iter - 1,
          charCode: TextUtils.zenKatakana[c - TextUtils.hanKatakana[0]],
        };
      } else {
        for (let i = 0; i < TextUtils.hanKatakanaDaku.length; i = i + 1) {
          if (
            c === TextUtils.hanKatakanaDaku[i][0] &&
            next === TextUtils.hanKatakanaDaku[i][1]
          ) {
            return {
              nextIter: iter,
              charCode: TextUtils.zenKatakanaDaku[i],
            };
          }
        }
        return {
          nextIter: iter - 1,
          charCode: TextUtils.zenKatakana[c - TextUtils.hanKatakana[0]],
        };
      }
    }
    return {
      nextIter: iter,
      charCode: c,
    };
  }

  /**
   * 全角ひらがなを全角カタカナ変換する
   *
   * @static
   * @param {string} s
   * @return {*}  {string}
   * @memberof TextUtils
   */
  public static zenHiraToZenKata(s: string): string {
    if (!s || s.length <= 0) {
      return s;
    }
    let result: string = "";
    for (let i = 0; i < s.length; i = i + 1) {
      const c: number = s.charCodeAt(i);
      if (c >= 0x3041 && c <= 0x3093) {
        result += String.fromCharCode(c + 0x0060);
      } else {
        result += String.fromCharCode(c);
      }
    }
    return result;
  }

  /**
   * 指定されたChar文字が半角カナどうかを判断する。
   *
   * @param c
   * @return boolean
   */
  private static isHanKatakana(c: number): boolean {
    return (
      c >= TextUtils.hanKatakana[0] &&
      c <= TextUtils.hanKatakana[TextUtils.hanKatakana.length - 1]
    );
  }

  private static readonly hanKatakana: number[] = [
    0xff61 /* 。 */, 0xff62 /* 「 */, 0xff63 /* 」 */, 0xff64 /* 、 */,
    0xff65 /* ・ */, 0xff66 /* ヲ */, 0xff67 /* ァ */, 0xff68 /* ィ */,
    0xff69 /* ゥ */, 0xff6a /* ェ */, 0xff6b /* ォ */, 0xff6c /* ャ */,
    0xff6d /* ュ */, 0xff6e /* ョ */, 0xff6f /* ッ */, 0xff70 /* ー */,
    0xff71 /* ア */, 0xff72 /* イ */, 0xff73 /* ウ */, 0xff74 /* エ */,
    0xff75 /* オ */, 0xff76 /* カ */, 0xff77 /* キ */, 0xff78 /* ク */,
    0xff79 /* ケ */, 0xff7a /* コ */, 0xff7b /* サ */, 0xff7c /* シ */,
    0xff7d /* ス */, 0xff7e /* セ */, 0xff7f /* ソ */, 0xff80 /* タ */,
    0xff81 /* チ */, 0xff82 /* ツ */, 0xff83 /* テ */, 0xff84 /* ト */,
    0xff85 /* ナ */, 0xff86 /* ニ */, 0xff87 /* ヌ */, 0xff88 /* ネ */,
    0xff89 /* ノ */, 0xff8a /* ハ */, 0xff8b /* ヒ */, 0xff8c /* フ */,
    0xff8d /* ヘ */, 0xff8e /* ホ */, 0xff8f /* マ */, 0xff90 /* ミ */,
    0xff91 /* ム */, 0xff92 /* メ */, 0xff93 /* モ */, 0xff94 /* ヤ */,
    0xff95 /* ユ */, 0xff96 /* ヨ */, 0xff97 /* ラ */, 0xff98 /* リ */,
    0xff99 /* ル */, 0xff9a /* レ */, 0xff9b /* ロ */, 0xff9c /* ワ */,
    0xff9d /* ン */, 0xff9e /* ゛ */, 0xff9f /* ゜ */,
  ];

  private static readonly zenKatakana: number[] = [
    0x3002 /* 。 */, 0x300c /* 「 */, 0x300d /* 」 */, 0x3001 /* 、 */,
    0x30fb /* ・ */, 0x30f2 /* ヲ */, 0x30a1 /* ァ */, 0x30a3 /* ィ */,
    0x30a5 /* ゥ */, 0x30a7 /* ェ */, 0x30a9 /* ォ */, 0x30e3 /* ャ */,
    0x30e5 /* ュ */, 0x30e7 /* ョ */, 0x30c3 /* ッ */, 0x30fc /* ー */,
    0x30a2 /* ア */, 0x30a4 /* イ */, 0x30a6 /* ウ */, 0x30a8 /* エ */,
    0x30aa /* オ */, 0x30ab /* カ */, 0x30ad /* キ */, 0x30af /* ク */,
    0x30b1 /* ケ */, 0x30b3 /* コ */, 0x30b5 /* サ */, 0x30b7 /* シ */,
    0x30b9 /* ス */, 0x30bb /* セ */, 0x30bd /* ソ */, 0x30bf /* タ */,
    0x30c1 /* チ */, 0x30c4 /* ツ */, 0x30c6 /* テ */, 0x30c8 /* ト */,
    0x30ca /* ナ */, 0x30cb /* ニ */, 0x30cc /* ヌ */, 0x30cd /* ネ */,
    0x30ce /* ノ */, 0x30cf /* ハ */, 0x30d2 /* ヒ */, 0x30d5 /* フ */,
    0x30d8 /* ヘ */, 0x30db /* ホ */, 0x30de /* マ */, 0x30df /* ミ */,
    0x30e0 /* ム */, 0x30e1 /* メ */, 0x30e2 /* モ */, 0x30e4 /* ヤ */,
    0x30e6 /* ユ */, 0x30e8 /* ヨ */, 0x30e9 /* ラ */, 0x30ea /* リ */,
    0x30eb /* ル */, 0x30ec /* レ */, 0x30ed /* ロ */, 0x30ef /* ワ */,
    0x30f3 /* ン */, 0x309b /* ゛ */, 0x309c /* ゜ */,
  ];

  private static readonly hanKatakanaDaku: number[][] = [
    [0xff76, 0xff9e] /* ガ */,
    [0xff77, 0xff9e] /* ギ */,
    [0xff78, 0xff9e] /* グ */,
    [0xff79, 0xff9e] /* ゲ */,
    [0xff7a, 0xff9e] /* ゴ */,
    [0xff7b, 0xff9e] /* ザ */,
    [0xff7c, 0xff9e] /* ジ */,
    [0xff7d, 0xff9e] /* ズ */,
    [0xff7e, 0xff9e] /* ゼ */,
    [0xff7f, 0xff9e] /* ゾ */,
    [0xff80, 0xff9e] /* ダ */,
    [0xff81, 0xff9e] /* ヂ */,
    [0xff82, 0xff9e] /* ヅ */,
    [0xff83, 0xff9e] /* デ */,
    [0xff84, 0xff9e] /* ド */,
    [0xff8a, 0xff9e] /* バ */,
    [0xff8b, 0xff9e] /* ビ */,
    [0xff8c, 0xff9e] /* ブ */,
    [0xff8d, 0xff9e] /* ベ */,
    [0xff8e, 0xff9e] /* ボ */,
    [0xff8a, 0xff9f] /* パ */,
    [0xff8b, 0xff9f] /* ピ */,
    [0xff8c, 0xff9f] /* プ */,
    [0xff8d, 0xff9f] /* ペ */,
    [0xff8e, 0xff9f] /* ポ */,
    [0xff73, 0xff9e] /* ヴ */,
  ];

  private static readonly zenKatakanaDaku: number[] = [
    0x30ac /* ガ */, 0x30ae /* ギ */, 0x30b0 /* グ */, 0x30b2 /* ゲ */,
    0x30b4 /* ゴ */, 0x30b6 /* ザ */, 0x30b8 /* ジ */, 0x30ba /* ズ */,
    0x30bc /* ゼ */, 0x30be /* ゾ */, 0x30c0 /* ダ */, 0x30c2 /* ヂ */,
    0x30c5 /* ヅ */, 0x30c7 /* デ */, 0x30c9 /* ド */, 0x30d0 /* バ */,
    0x30d3 /* ビ */, 0x30d6 /* ブ */, 0x30d9 /* ベ */, 0x30dc /* ボ */,
    0x30d1 /* パ */, 0x30d4 /* ピ */, 0x30d7 /* プ */, 0x30da /* ペ */,
    0x30dd /* ポ */, 0x30f4 /* ヴ */,
  ];

  private static readonly zenKatakanaKigo: number[][] = [
    [0x3002, 0xff61] /* 。 */,
    [0x300c, 0xff62] /* 「 */,
    [0x300d, 0xff63] /* 」 */,
    [0x3001, 0xff64] /* 、 */,
    [0x30fb, 0xff65] /* ・ */,
    [0x309b, 0xff9e] /* ゛ */,
    [0x309c, 0xff9f] /* ゜ */,
    [0x30fc, 0xff70] /* ー */,
  ];

  private static readonly zenkataTbl: number[] = [
    // 以下の文字は対応する半角カナなし
    // 0x30ee(ヮ),0x30f0,0x30f1
    0x30a1 /* ァ */, 0x30a2 /* ア */, 0x30a3 /* ィ */, 0x30a4 /* イ */,
    0x30a5 /* ゥ */, 0x30a6 /* ウ */, 0x30a7 /* ェ */, 0x30a8 /* エ */,
    0x30a9 /* ォ */, 0x30aa /* オ */, 0x30ab /* カ */, 0x30ac /* ガ */,
    0x30ad /* キ */, 0x30ae /* ギ */, 0x30af /* ク */, 0x30b0 /* グ */,
    0x30b1 /* ケ */, 0x30b2 /* ゲ */, 0x30b3 /* コ */, 0x30b4 /* ゴ */,
    0x30b5 /* サ */, 0x30b6 /* ザ */, 0x30b7 /* シ */, 0x30b8 /* ジ */,
    0x30b9 /* ス */, 0x30ba /* ズ */, 0x30bb /* セ */, 0x30bc /* ゼ */,
    0x30bd /* ソ */, 0x30be /* ゾ */, 0x30bf /* タ */, 0x30c0 /* ダ */,
    0x30c1 /* チ */, 0x30c2 /* ヂ */, 0x30c3 /* ッ */, 0x30c4 /* ツ */,
    0x30c5 /* ヅ */, 0x30c6 /* テ */, 0x30c7 /* デ */, 0x30c8 /* ト */,
    0x30c9 /* ド */, 0x30ca /* ナ */, 0x30cb /* ニ */, 0x30cc /* ヌ */,
    0x30cd /* ネ */, 0x30ce /* ノ */, 0x30cf /* ハ */, 0x30d0 /* バ */,
    0x30d1 /* パ */, 0x30d2 /* ヒ */, 0x30d3 /* ビ */, 0x30d4 /* ピ */,
    0x30d5 /* フ */, 0x30d6 /* ブ*/, 0x30d7 /* プ */, 0x30d8 /* ヘ */,
    0x30d9 /* ベ */, 0x30da /* ペ */, 0x30db /* ホ */, 0x30dc /* ボ */,
    0x30dd /* ポ */, 0x30de /* マ */, 0x30df /* ミ */, 0x30e0 /* ム */,
    0x30e1 /* メ */, 0x30e2 /* モ */, 0x30e3 /* ャ */, 0x30e4 /* ヤ */,
    0x30e5 /* ュ */, 0x30e6 /* ユ */, 0x30e7 /* ョ */, 0x30e8 /* ヨ */,
    0x30e9 /* ラ */, 0x30ea /* リ */, 0x30eb /* ル */, 0x30ec /* レ */,
    0x30ed /* ロ */, 0x30ee /* ヮ */, 0x30ef /* ワ */, 0x30f0 /* ヰ */,
    0x30f1 /* ヱ */, 0x30f2 /* ヲ */, 0x30f3 /* ン */, 0x30f4 /* ヴ */,
  ];

  /**
   * 両端の空白文字を削除する。
   *
   * @static
   * @param {string} value
   * @return {*}  {string}
   * @memberof TextUtils
   */
  public static trimBoth(value: string): string {
    if (!value || value.length <= 0) {
      return value;
    }
    value = TextUtils.trimLeft(value);
    value = TextUtils.trimRight(value);
    return value;
  }

  /**
   * 先端の空白文字を削除する
   *
   * @static
   * @return {*}  {string}
   * @memberof trimLeft
   */
  public static trimLeft(value: string): string {
    if (!value || value.length <= 0) {
      return value;
    }
    let i = 0;
    for (i; i < value.length; i = i + 1) {
      const c = value.charCodeAt(i);
      if (i === 0 && !(c === 0x0020 || c === 0x3000)) {
        return value;
      }
      if (!(c === 0x0020 || c === 0x3000)) {
        break;
      }
    }
    return value.substr(i, value.length);
  }

  /**
   * 終端の空白文字を削除する
   *
   * @static
   * @return {*}  {string}
   * @memberof trimRight
   */
  public static trimRight(value: string): string {
    if (!value || value.length <= 0) {
      return value;
    }
    value = value.split("").reverse().join("");
    value = TextUtils.trimLeft(value);
    return value.split("").reverse().join("");
  }
}
