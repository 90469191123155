import * as React from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// material ui
import { Grid } from "@material-ui/core";

import { convertDateFormat } from "../../utils/date";

// from assets
import Appbar from "../Layout/AppBar";

import Footer from "../Layout/Footer";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "moment/locale/ja";

// styles
import "./styles.css";

// interfaces
import { Props, IStates } from "../../interfaces/ConfirmReservation";

// api call
import {
  createCustomerReservation,
  createCustomerReservationOnPrem,
  getAvailabilityOfAgents,
  getNumberOfUnassignedReservation,
  checkWithinOfficeHours,
  sendNotif,
} from "../../api";
import StepBar from "../Common/StepBar";
import Loading from "../Common/Loading";

// constants
const PHONE_NUMBER_EXIT_CDOE = "+";
const BAD_REQUEST = 400;
const BAD_GATEWAY = 502;
const GATEWAY_TIMEOUT = 504;
// StepBar設定
const steps = {
  step: 1,
};

class Review extends React.Component<Props, IStates> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      currentValue: 0,
      language: localStorage.getItem("language"),
      email: "",
      selectedConversationId: "",
      receiverId: "",
      receiverName: "",
      holder: this?.props?.location?.state,
      agreeCheckbox: false,
      response: "",
      reservationError: false,
      hasAvailableOperator: false,
      isButtonDisabled: false,
      isLoading: false,
      clickEventHandling: false,
      confirmSavedOnCloud: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    let a = this.props.history;
    let b = this.state.holder;
    window.addEventListener("popstate", function () {
      a.push({
        pathname: "/reservation",
        state: {
          first_name: b.first_name,
          last_name: b.last_name,
          firstNameFurigana: b.firstNameFurigana,
          lastNameFurigana: b.lastNameFurigana,
          phoneNumber: b.phoneNumber,
          phoneType: b.phoneType,
          birthdayYear: b.birthdayYear,
          birthdayMonth: b.birthdayMonth,
          birthdayDay: b.birthdayDay,
          firstSeletY: b.firstSeletY,
          firstSeletM: b.firstSeletM,
          firstSeletD: b.firstSeletD,
          selectYearFlag: b.selectYearFlag,
          selectMonthFlag: b.selectMonthFlag,
          selectDayFlag: b.selectDayFlag,
          availableAgents: b.availableAgents,
          inPreparingAgents: b.inPreparingAgents,
        },
      });
    });
  }

  redirectToNoAvailableOperators() {
    this.props.history.push({
      pathname: "/noavailableoperator",
      state: {
        holder: this.state.holder,
      },
    });
  }

  async checkAvailableOperators() {
    const clickEventHandling = this.state.clickEventHandling;
    this.setState({
      clickEventHandling: true,
    });
    setTimeout(async () => {
      if (clickEventHandling) return;
      this.setState({
        isButtonDisabled: true,
        isLoading: true,
      });
      try {
        const result = await checkWithinOfficeHours();
        if (result.withinOfficeHours) {
          const unassignedReservation =
            await getNumberOfUnassignedReservation();
          const data = await getAvailabilityOfAgents();
          this.checkOperatorStatus(
            data.result,
            unassignedReservation.numberOfReservationOnQueue.message
          );
        } else {
          this.redirectToNoAvailableOperators();
        }
      } catch (e) {
        this.redirectToNoAvailableOperators();
      }
      this.setState({
        clickEventHandling: false,
      });
    }, 1000);
  }

  checkOperatorStatus(agents: any, unassignedReservations: any) {
    if (
      (agents.agentsInAvailable === 0 && agents.agentsInPreparing === 0) ||
      agents.agentsInAvailable + agents.agentsInPreparing <=
        unassignedReservations
    ) {
      this.redirectToNoAvailableOperators();
    } else if (agents.agentsInPreparing > 0) {
      this.reserveMeeting(false);
    } else {
      this.reserveMeeting(true);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRandomString(length: any) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  async reserveMeeting(hasAvailable: boolean) {
    var meetingID = this.getRandomString(6);
    var reservationKey = `${this.getRandomString(6)}${Math.trunc(
      Date.now() / 1000
    )}`;
    var name = this.state.holder.first_name + " " + this.state.holder.last_name;
    var countryCode =
      window.location.hostname === "localhost"
        ? process.env.REACT_APP_PHILIPPINES_COUNTRY_CODE
        : process.env.REACT_APP_JAPAN_COUNTRY_CODE;
    var mobileNumber =
      PHONE_NUMBER_EXIT_CDOE +
      countryCode +
      this.state.holder.phoneNumber.toString();
    var birthdayMonth =
      this.state.holder.birthdayMonth.toString().length !== 1
        ? this.state.holder.birthdayMonth
        : `0${this.state.holder.birthdayMonth}`;
    var birthdayDay =
      this.state.holder.birthdayDay.toString().length !== 1
        ? this.state.holder.birthdayDay
        : `0${this.state.holder.birthdayDay}`;
    let retryCount = 0

    const payload = {
      action:
        this.state.holder.meetingId === "" ||
        this.state.holder.meetingId === undefined
          ? "setReservation"
          : "updateCustomerReservation",
      name: name,
      first_name: this.state.holder.first_name,
      last_name: this.state.holder.last_name,
      firstNameFurigana: this.state.holder.firstNameFurigana,
      lastNameFurigana: this.state.holder.lastNameFurigana,
      birthday:
        this.state.holder.birthdayYear.substring(0, 4) +
        "-" +
        birthdayMonth +
        "-" +
        birthdayDay,
      number: mobileNumber.includes("+")
        ? mobileNumber
        : "+" + mobileNumber.toString(),
      created: convertDateFormat(new Date(), "YYYY-MM-DDTHH:mm:ss"),
      reservationDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
      meetingID: meetingID.toString(),
      reservationKey: reservationKey.toString(),
      clientName: process.env.REACT_APP_CLIENT,
      phoneType: this.state.holder.phoneType,
      availableAgents: this.state.holder.availableAgents,
      inPreparingAgents: this.state.holder.inPreparingAgents,
    };

    const onPremPayload = {
      reservationKey: reservationKey.toString(),
      lastNameKanji: this.state.holder.last_name,
      firstNameKanji: this.state.holder.first_name,
      lastNameKana: this.state.holder.lastNameFurigana,
      firstNameKana: this.state.holder.firstNameFurigana,
      birthday:
        this.state.holder.birthdayYear.substring(0, 4) +
        "-" +
        birthdayMonth +
        "-" +
        birthdayDay,
      phoneNumber: this.state.holder.phoneNumber.toString(),
      phoneType: this.state.holder.phoneType,
      status: "",
    };

    var onPremRequest, onCloudRequest;
    
    do{
      try {
        // Saving reservation key to cloud DB.
        onCloudRequest = await createCustomerReservation(payload);
        if (onCloudRequest.message == "No Available Operator") {
          retryCount = 3
          this.redirectToNoAvailableOperators();
        } else {
          retryCount = 3
          try {
            // Saving reservation details to On-Prem DB.
            onPremRequest = await createCustomerReservationOnPrem(onPremPayload);
            this.redirectToWaitingOperator(hasAvailable);
          } catch (e) {
            this.errorHanderOnPrem(e);
            return;
          }
        }
      } catch (e) {
        if (retryCount === 2)this.errorHander(e);
        retryCount++
      }
    
      if (
        onPremRequest && onPremRequest.status === "SUCCESS" &&
        onCloudRequest.statusCode === 200
      ) {
        // Sending Notification on UAP
        try {
          await sendNotif(payload);
        } catch (err) {
        }
      }
    } while (retryCount < 3)
  }

  errorHanderOnPrem(e: any) {
    // When validation error occurs
    if (e?.response?.status === BAD_REQUEST) {
      // Redirect to reservation form
      this.redirectToReservation(e.response.data);
      return;
    }

    // Display common error page
    this.redirectToNoAvailableOperators();
    return;
  }

  errorHander(e: any) {
    // When no valid response from CF API call
    if (e.code === "ECONNABORTED" && e.message.indexOf("timeout") !== -1) {
      // Redirect to waiting for operator page
      this.redirectToWaitingOperator(false);
      return;
    }

    // Display common error page
    this.redirectToNoAvailableOperators();
    return;
  }

  redirectToReservation(data: any) {
    this.props.history.push({
      pathname: "/reservation",
      state: {
        first_name: this.state.holder.first_name,
        last_name: this.state.holder.last_name,
        firstNameFurigana: this.state.holder.firstNameFurigana,
        lastNameFurigana: this.state.holder.lastNameFurigana,
        birthdayYear: this.state.holder.birthdayYear,
        birthdayMonth: this.state.holder.birthdayMonth,
        birthdayDay: this.state.holder.birthdayDay,
        phoneNumber: this.state.holder.phoneNumber,
        phoneType: this.state.holder.phoneType,
        firstSeletY: this.state.holder.firstSeletY,
        firstSeletM: this.state.holder.firstSeletM,
        firstSeletD: this.state.holder.firstSeletD,
        selectYearFlag: this.state.holder.selectYearFlag,
        selectMonthFlag: this.state.holder.selectMonthFlag,
        selectDayFlag: this.state.holder.selectDayFlag,
        errData: data,
        availableAgents: this.state.holder.availableAgents,
        inPreparingAgents: this.state.holder.inPreparingAgents,
      },
    });
  }

  redirectToWaitingOperator(hasAvailableOP: boolean) {
    this.props.history.push({
      pathname: "/confirmation",
      state: {
        hasAvailableOperator: hasAvailableOP,
      },
    });
  }

  handleHTMLButtonClick(data: any, item: any) {
    if (item.id === "openCalendar") {
      var customerName = this.state.email;

      data.customerName = customerName;

      this.props.history.push({
        pathname: "/calendar",
        state: data,
      });
    } else if (item.id === "applicationProcedure") {
      this.props.history.push({
        pathname: "/selectPlan",
        state: data,
      });
    }
  }

  handleCorrectButton() {
    const clickEventHandling = this.state.clickEventHandling;
    this.setState({
      clickEventHandling: true,
    });
    setTimeout(async () => {
      if (clickEventHandling) return;
      this.props.history.push({
        pathname: "/reservation",
        state: {
          first_name: this.state.holder.first_name,
          last_name: this.state.holder.last_name,
          firstNameFurigana: this.state.holder.firstNameFurigana,
          lastNameFurigana: this.state.holder.lastNameFurigana,
          phoneNumber: this.state.holder.phoneNumber,
          phoneType: this.state.holder.phoneType,
          birthdayYear: this.state.holder.birthdayYear,
          birthdayMonth: this.state.holder.birthdayMonth,
          birthdayDay: this.state.holder.birthdayDay,
          firstSeletY: this.state.holder.firstSeletY,
          firstSeletM: this.state.holder.firstSeletM,
          firstSeletD: this.state.holder.firstSeletD,
          selectYearFlag: this.state.holder.selectYearFlag,
          selectMonthFlag: this.state.holder.selectMonthFlag,
          selectDayFlag: this.state.holder.selectDayFlag,
        },
      });
      this.setState({
        clickEventHandling: false,
      });
    }, 1000);
  }
  topFunction = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <React.Fragment>
        <Translation>
          {(t, { i18n }) => (
            <Grid container>
              <Grid item xs={12}>
                <Appbar
                  appbarTitle={"Online Consultation"}
                  withCallback={false}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="ReservationForm-form">
                  <Grid>
                    <div className="ReservationForm-Title">
                      オンライン相談のお申込 確認
                    </div>
                    <StepBar {...steps}></StepBar>
                    <div className="Review-titleMessage">
                      以下の内容でよろしければ「この内容で申込む」ボタンを押してください。
                    </div>
                    <div className="Review-titleMessage-se">
                      入力内容を修正する場合は「修正する」ボタンを押してください。
                    </div>
                  </Grid>
                  <Grid className="Review-form">
                    <div className="ReservationForm-agreementText">
                      ご本人さま情報
                    </div>
                    <div className="ReservationForm-conform">
                      <div className="ReservationForm-label">氏名</div>
                      <div className="Review-values-fullName">
                        {this.state.holder.last_name}{" "}
                        {this.state.holder.first_name}
                      </div>
                      <div className="straightLine"></div>
                      <div className="ReservationForm-label">フリガナ</div>
                      <div className="Review-values-fullName">
                        {this.state.holder.lastNameFurigana}{" "}
                        {this.state.holder.firstNameFurigana}
                      </div>
                      <div className="straightLine"></div>
                      <div className="ReservationForm-label">生年月日</div>
                      <div className="Review-values-fullName">
                        {this.state.holder.birthdayYear}年
                        {this.state.holder.birthdayMonth}月
                        {this.state.holder.birthdayDay}日
                      </div>
                      <div className="straightLine"></div>
                      <div className="ReservationForm-label">携帯電話番号</div>
                      <div className="Review-values-fullName">
                        {this.state.holder.phoneNumber}
                      </div>
                      <div className="straightLine"></div>
                      <div className="ReservationForm-label">
                        携帯電話の種類
                      </div>
                      <div className="Review-values-fullName">
                        {this.state.holder.phoneType}
                      </div>
                      <div className="straightLine"></div>
                    </div>
                  </Grid>

                  <button
                    className="ReservationForm-submitButton"
                    onClick={this.checkAvailableOperators.bind(this)}
                    disabled={this.state.isButtonDisabled}
                  >
                    この内容で申込む
                    <ArrowForwardIosIcon />
                  </button>
                  <button
                    className="ReservationForm-returnButton"
                    onClick={this.handleCorrectButton.bind(this)}
                  >
                    修正する
                  </button>
                  <button
                    onClick={() => this.topFunction()}
                    className="ReservationForm-backToTopButton"
                  >
                    <ArrowUpwardIcon />
                    　このページのTOPへ戻る
                  </button>
                </div>
              </Grid>
              <Footer />
            </Grid>
          )}
        </Translation>
        {this.state.isLoading ? <Loading modalvisible={true} /> : null}
      </React.Fragment>
    );
  }
}

export default withRouter(Review);
