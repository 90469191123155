import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./styles.css";
import EndPage from "../EndPage";
import AppBar from "../Layout/AppBar";
import Footer from "../Layout/Footer";
import { Grid } from "@material-ui/core";
interface Props extends RouteComponentProps<any> {}

class Feedback extends React.Component<Props, {}> {
  contextOfEndPage = {
    title: "ご利用ありがとうございました。",
    noticeInfo: "",
    innerHTML:
      "今後も、ご不明な点やご質問がございましたら、オンライン相談へお申込のうえ、ご相談ください。<br/><br/>" +
      "オンライン相談<br/>" +
      "受付時間 9:00-18:00",
    buttonName: "HOMEへ戻る",
    operatorPhoneNumber: false,
    displayButton: true,
  };
  render() {
    return (
      <Grid container className="Endpage-grid-container">
        <Grid item xs={12}>
          <AppBar appbarTitle={"Online Consultation"} withCallback={false} />
        </Grid>
        <EndPage {...this.contextOfEndPage} />
        <Footer />
      </Grid>
    );
  }
}

export default withRouter(Feedback);
