/**
 * 関東財務局長番号生成用Utils
 *
 * @export
 * @class GenerateKantoFinanceBureauDirectorNo
 */
export class GenerateKantoFinanceBureauDirectorNo {
  /**
   *  関東財務局長番号生成する
   *
   * @static
   * @return {*}  {string}
   * @memberof trimRight
   */
  public static generateKantoFinanceBureauDirectorNo(): string {
    const kijunDate = "0121";
    const curDate = new Date();
    let fullYear = curDate.getFullYear();
    let fullMonth = (curDate.getMonth() + 1).toString();
    let month = fullMonth.length !== 1 ? fullMonth : `0${fullMonth}`;

    let fullDay = curDate.getDate().toString();
    let day = fullDay.length !== 1 ? fullDay : `0${fullDay}`;
    if (kijunDate > month + day) {
      fullYear = fullYear - 1;
    }
    return ((fullYear - 1984) / 3 + 1).toString().substring(0, 2);
  }
}
