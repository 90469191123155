export default {
  sample2: `重要事項のご説明
「重要事項のご説明」の書面には、ご契約に際して、お客さまが保険商品の内容を理解するために特にご確認いただきたい事項や、特にご注意いただきたい事項が記載されていますので、必ずご確認および受領いただきますようお願いいたします。
中でも、保険金をお支払いしない主な場合など、お客さまにとって不利益となる事項が記載されている箇所については、特に注意してお読みください。
お客さまと記名被保険者が異なる場合は、お客さまから記名被保険者の方にもこの書類に記載されている内容をご説明いただきますようお願いいたします。
万一事故が起きた場合は、事故受付窓口を記載していますので速やかにご連絡ください。`,
  //OnBoarding
  "START USING REMOTE SALES": "リモート販売の使用を開始する",
  "SHORTCUT SETTINGS": "ショートカット設定",
  "PASSWORD SETTINGS": "パスワード設定",
  "For security, change the password to something you like.":
    "セキュリティ確保のため、新しいパスワードを設定してください",
  "This is the screen to start using the service dedicated to communication with ‘Name of Car Insurance’ agents. Make sure that the customer name is correct, and enter the initial access key received from the agency in charge.":
    "リモート販売を利用開始するための画面です。お客さまのお名前が正しいことを確認し、代理店の担当者から連絡されたアクセスキーを入力してください。",
  "Click on “More Options” at the bottom page of Microsoft Edge Browser.":
    "Microsoft Edge Browserの下部ページにある[その他のオプション]をクリックします。",
  // Video Call
  "Meeting Details": "面談予約詳細",
  "John Doe": "〇〇株式会社",
  Agent: "山田　太郎",
  "Meeting Agenda": "面談内容",
  "Values for Agenda": "自動車保険の継続手続き",
  Schedule: "面談日時",
  "Entering Message": "面談開始00分前からオンライン会議に入室できます",
  "Video Call": "ビデオ面談",
  Messages: "メッセージ",
  "Notice Title": "※注意事項※",
  "Notice Description 1": "相談時の録画・録音は禁止、SNSへ公開も禁止",
  "Notice Description 2": "相談時は、個人情報が他人（2親等以外）に見られない、",
  "Notice Description 2.5": "聞かれないような場所の確保やイヤフォン等を​推奨",
  // Login
  Username: "ユーザー名",
  "Enter username": "ユーザーネームを入力してください",
  "Customer Brain Login": "お客さま 利用開始",
  "E-mail address": "メールアドレス",
  "Enter E-mail address": "メールアドレスを入力してください",
  Password: "パスワード",
  "Enter Password": "パスワードを入力してください",
  Login: "ログインする",
  "Sign in": "サインイン",
  "Sign in Message":
    "エージェントとの専用通信サービスの利用を開始する画面です。お客様ページへのログイン情報をお持ちの方は、入力してご利用ください。",
  // "Password error": "パスワードが間違っています",
  "Password error": "ユーザーネームまたはパスワードが違います！",
  "Join Meeting": "面談開始",
  "Meeting ID": "会議ID",
  Continue: "続ける",
  Connecting: "接続しています...",
  "Waiting for others to join": "他の人が参加するのを待っています...",
  "Entering to Conference room": "会議室に入る。お待ちください...",
  "Preparing devices": "デバイスを準備しています...",
  "Connecting to Conference Session": "会議セッションに接続しています...",
  // homepage
  "MS1 Customer Brain": "リモート販売のお客様",
  "Agency Chat": "代理店チャット",
  "Agency Chats": "チャット",
  "Accident Contact": "緊急連絡先",
  "Change Procedure": "変更手順",
  "Agency Information": "代理店情報",
  "Contract Continuation": "契約更改",
  Meetings: "ミーティング",
  "Need Help?": "助けが必要？",
  Home: "ホーム",
  returnHome: "ホームに戻る",
  Chat: "チャット",
  Notifications: "通知",
  "Search...": "探す...",
  "To:": "に：",
  Select: "選択する",
  "No Available Data": "利用可能なデータはありません",
  "Write your reply here...": "ここに返信を書いてください...",
  "My Calendar": "カレンダー",
  //application confirmation
  "Application Confirmation": "申込確認",
  "Name of the person to be procedure (in Kanji)":
    "お手続きされる方の氏名（漢字）",
  "Name of the person to be procedure (in Kana)":
    "お手続きされる方の氏名（カナ）",
  "Mail Address": "お客さまメールアドレス",
  "Mail Address (re-entry)": "お客さまメールアドレス (再入力)",
  "Check the Content and move on": "内容を確認して次へ",

  //select plan
  "Select Plan": "プラン選択",
  "Insurance Type": "保険種類",
  "Conditions of Contract": "ご契約条件",
  "Limited driver": "運転者限定",
  "Condition of driver's age": "運転者年齢条件",
  "Purpose of use": "使用目的",
  "Damages to the other Person": "相手への賠償",
  "Bodily Injury (per person)": "対人賠償（１名につき）",
  "Property Damage Liability/Deductible (per accident)":
    "対物賠償／免責金額（１事故につき）",
  Rider: "特約",
  "Injury Coverage": "おケガの補償",
  "Personal Injury": "人身傷害",
  "Coverage (per person)": "補償範囲（１名につき）",
  "Rider and Road Service": "特約・ロードサービス",
  "Road service costs": "ロードサービス費用",
  "Road service": "ロードサービス",
  "Other coverage and special riders": "その他の補償・特約",
  "Other coverage and special riders (Indicates the name of the indemnity/specialty to be set)":
    "その他の補償・特約（セットされる補償・特約名称を表示します）",
  "Other Rider": "その他特約",
  "Discounts, premiums, etc.": "割引・割増など",
  "Car Insurance": "お車の保険",
  "Vehicle Insurance": "車両保険",
  "Type of Contract": "ご契約のタイプ",
  "Vehicle insurance amount": "保険金額",
  Deductible: "免責金額",

  //view policy
  "Please select how you would like to view the policy and policy leaflet (policy conditions) from one of the following options.":
    "保険証券・ご契約のしおり（約款）の閲覧方法を以下のいずれかよりお選びください。",
  "Insurance policy web browsing only. Terms of insurance policy Web browsing only":
    "保険証券：Web閲覧のみ 約款：Web閲覧のみ",
  "Insurance policy delivered on paper. Terms of insurance policy Web browsing only":
    "保険証券：紙でお届け 約款：Web閲覧のみ",
  "Insurance policy delivered on paper. Terms of insurance policy delivered on paper":
    "保険証券：紙でお届け 約款：紙でお届け",
  "To Confirmation of Procedures": "お手続き経緯確認へ",
  "How to view your insurance policy": "証券の閲覧方法",

  //important matter
  "Display 'Important Information'": "「重要事項のご説明」を表示",
  "View 'Confirmation Guide'": "「ご確認の手引」を表示",
  "We have received and reviewed the 'Explanation of Important Matters'.":
    "「重要事項のご説明」を受領し、内容を確認しました。",
  "Explanation of Important Matter": "重要事項説明",

  //Reconfirmation
  Reconfirmation: "再確認",
  "Period of Insurance": "保険期間",
  "How to pay insurance premiums": "保険料払込方法",
  "Name of the Applicants (policyholder)": "申込人（保険契約者）氏名",
  "Registered Insured Person": "記名被保険者",
  "Color of the License of the named insured": "記名被保険者の免許の色",
  "Registration Number": "登録番号",
  "Chassis Number": "車台番号",
  "Vehicle Type": "用途車種",

  //summary
  "Insurance Contractor": "保険契約者",
  "Place of Procedure": "お手続場所",
  "Date of Procedure": "お手続日時",
  "Name of Agent": "募集人名",
  "Final Confirmation": "最終のご確認事項",
  Application: "申込",

  //Getting started
  "Shortcut Settings": "ショートカット作成",
  "On the home screen of the smartphone, it is convenient to put a shortcut. You can set a Remote Sales Customer App shortcut on the home screen of your smartphone.":
    "スマートフォンのホーム画面にお客さまRemote Salesのショートカットを置くと便利です。",
  "Getting Started": "ショートカットを作成する",

  //Shortcut settings
  "Customer Brain Shortcut Setting": "お客さまのショートカット作成方法",
  "Click on “More Options” on the Chrome Browser.":
    "Google Chromeの三点リーダーをクリックします。",
  "Locate and Select “Add to Home Screen”": "「ホーム画面に追加」を選択します",
  "Edit Description (as needed)": "ショートカット名を入力します",
  "Shortcut should appear on home screen now.":
    "ショートカットがホーム画面に表示されます。",

  "Application Procedure": "申込手続き",
  "Summary Details": "ご契約内容の概要",
  "Applicant's Home": "お手続きされた方の自宅",
  "Applicant's Office": "お手続きされた方の勤務先",
  "Agency Office": "代理店事務所",
  "Plan 1": "プラン１",
  "Plan 2": "プラン2",
  "Plan 3": "プラン3",
  Premium: "保険料",
  "Plan Confirmation": "プランの確認",
  No: "いいえ",
  "Please select a plan for your preference":
    "ご希望のプランを選択してください",
  "Semi-premium": "保険料",
  Regular: "保険料",
  "Total  ¥17,600": "合計17,600円",
  "View Policy": "次へ",
  Yes: "はい",
  Others: "その他",
  "No Available Schedule": "調整可能な日程がありません",
  "Get Started": "ショートカットを作成する",
  "Click on “More Options” at the bottom page of Microsoft Edge Browser.":
    "Microsoft Edgeのメニューボタンをクリックします。",
  "Click on “More Options” on the Mozilla Firefox Browser.":
    "Firefoxのメニューボタンをクリックします。",
  "Locate and Select “Page” and “Add Page Shortcut”":
    "「ページ」と「ページのショートカットを追加」を見つけて選択します",
  "Select your preferred browser to save the shortcut":
    "お好みのブラウザを選択して、ショートカットを保存します",

  //Pension Modal
  "Register Pension": "年金登録",
  "Old Age Pension Claim Information Registration": "老齢年金請求情報 登録",
  Bank: "銀行",
  Branch: "支店",
  "Account Holder": "口座名義",
  Type: "種別",
  "Account Number": "口座番号",
  "Date of Start Receiving Pension": "年金受取開始年月",

  "Mizuho Bank": "みずほ銀行",
  "Japan Post Bank": "ゆうちょ銀行",
  "Akasaka Branch": "赤坂支店",
  "Suginami Branch": "杉並支店",
  "Ichiro Suzuki": "スズキ　イチロウ",
  "Saving Account": "普通",
  "Checking Account": "当座",

  //Schedule Consultation

  "Schedule Consultation": "相談予約",
  "Consultation Details": "相談日時",
  "Please choose your preferred consultation date":
    "ご希望の相談日をお選びください",
  "Please choose your preferred consultation time":
    "ご希望の相談時間をお選びください",
  "Please choose your preferred interview method":
    "ご希望の面談方法をお選びください",
  "Cancel Consultation": "キャンセル",
  "Proceed to Next Step": "次へ",
  "Start Time": "開始時間",
  "End Time": "終了時間",
  "Basic Customer Information": "お客様基本情報",
  Questions: "相談前質問事項",
  "Confirm Consultation": "登録",
  "Schedule Consultation Success!": "登録完了",
  "You have successfully scheduled a consultation with an agent.":
    "相談予約が完了致しました",
  "Go To Homepage": "ホームページへ戻る",
  "Are you sure you want to cancel scheduling a consultation?":
    "相談予約をキャンセルしますか？",
  "You will not be able to save your scheduled consultation if you choose ‘Cancel Changes’":
    "「変更をキャンセル」を選択した場合、相談予約はキャンセルされます",
  "Go Back": "戻る",
  "Cancel Changes": "変更をキャンセル",

  "Consultation on old-age pension": "老齢年金に関する相談",
  "Consultation on disability pension": "障害年金に関する相談",
  "Consultation on survivor's pension": "遺族年金に関する相談 ",

  "Web Interview": "ウェブ面談",
  "Visit Interview": "対面面談",

  Ruby: "フリガナ​",
  "Consultation Content": "相談内容",
  "Basic Pension Number": "基礎年金番号",
  "Date of Birth": "生年月日",
  "Consultation date and time": "相談日時",

  " years old": "歳",

  "Do you receive an old age pension claim?": "老齢請求を受け取りますか？",
  "Do you currently have a spouse who is married in the family register?":
    "現在、戸籍上の婚姻関係にある配偶者はいますか？",
  "Do you have a welfare pension for more than 20 years?":
    "厚生年金の加入期間は20年以上ありますか？",
  "Is your spouse's annual income less than 8.5 million yen?":
    "配偶者の年収は850万円未満ですか？",
  "Is your spouse in your dependents?": "配偶者はあなたの扶養に入ってますか？",
  "Is your spouse over 56 years old?": "配偶者の年齢は65歳以上ですか？",
  "Do you have children?": "お子さんはいますか？",
  "Do you have any children who meet the criteria of choice?":
    "選択肢の条件に当てはまるお子さんはいますか？",
  "Did you live in Okinawa from April 1, 1958 to May 14, 1972 after you became an adult?":
    "成人してから昭和36年4月1日～昭和47年5月14日の期間に沖縄に居住していましたか？",
  "Before the end of compulsory education": "義務教育終了前",

  Upload: "アップロード​",

  "Pension book": "年金手帳",
  "Most recent employment insurance card": "直近の雇用保険被保険者証",
  "Certificate of Family Register": "戸籍謄本",
  "Certificate of Residence with whole household": "世帯全員の住民票",
  "Bank book/Cash card": "銀行通帳/キャッシュカード",
  "Spouse's income certificate": "配偶者の所得証明書",
  Reference: "参照​",

  "Online Consultation": "オンライン相談",
  "Reservation Details": "予約フォーム",
  "Mobile phone number": "携帯電話番号",
  Email: "メールアドレス",
  "Choose Date": "日付選択",
  "Reservable Time": "時間帯を選択してください",
  Proceed: "続ける",
  Cancel: "キャンセル",
  "Online Consultation is Successfully Reserved!":
    "オンライン相談は正常に予約されました",
  "The content of the created online consultation was sent by short message.":
    "作成したオンライン相談の内容はショートメッセージで送信されました",
  "Go To Meeting": "会議に行く",
  "Go To Meeting List": "ミーティングリストに移動",
  "Go To HomePage": "ホームページへ",
  "Are you sure you want to cancel your schedule?":
    "スケジュールをキャンセルしてもよろしいですか？",
  "All your updates will not be saved if you click ‘Cancel Changes’":
    "[変更をキャンセル]をクリックすると、すべての更新が保存されません",
  "Review Reservation Details": "予約詳細",
  Reserve: "予約する",
  "Update Details": "更新の詳細",
  Return: "戻る",
  "Email is not a valid format": "メールは有効な形式ではありません",
  "Full Name": "氏名",
  "Date and Time": "日時",
  "Phone Type": "電話の種類",
  Help: "助けて",
  "Smartphone window (reservation completed)": "スマホ窓口（予約完了）​",
  "Thank you for the reservation": "ご予約ありがとうございます。",
  "Reservation reception number": "予約受付番号",
  "Check the SMS to see reservation details and enter the video call from the URL on the SMS.​":
    "ご予約ありがとうございます。オンライン相談のURLをSMSでお送りしますのでご確認ください。",

  "Thank you for using the smartphone window. We look forward to hearing from you in order to provide better services in the future.":
    "スマホ窓口をご利用いただきありがとうございます。​今後のよりよいサービスご提供のために、みなさまからのご意見をお待ちしております。",
  Feedback: "フィードバック",
  "How was the call quality?": "通話の品質はいかがでしたか？",
  "How was the response of the person in charge?":
    "担当者の対応はいかがでしたか？",
  "Please write any additional comments.":
    "追加コメントがあればご記入ください。",
  Send: "送信する",
  Close: "閉じる",
  Correct: "予約内容を修正する",
  "I Agree": "上記に同意する",
  "Consult Now": "今すぐ相談する",
  "Specify the Date and Time": "日時を指定する:",
  "Quick Contract": "スピード重視でお手続",
  "Ask Operator": "問い合わせ",
  "Need Help with Operation": "操作がわからない",
  "Need Consultation": "じっくり相談",
  Other: "その他",
  "Consultation Date and Time": "相談日時",
  "Terms & Condition": `「個人情報のお取り扱い」および「ご利用規約」について 

ご予約にあたり、以下の「個人情報の利用目的」および「ビデオ相談」をご確認、同意のうえ、次の画面にお進みください 

＜個人情報の利用目的＞ 

ご入力いただいた個人情報については、以下の利用目的の範囲で利用させていただくことがあります。 

１.ビデオ相談窓口の予約、ご連絡、その他お客様相談等への対応のため 

２．市場調査、ならびにデータ分析およびアンケートの実施等よる当社金融商品やサービスの 

      研究や開発のため、 `,
  "Please Select": "選択してください",
  "Fill up form below to reserve your consultation meeting.":
    "相談予約はこちら",
  "Please input full name": "氏名を入力してください",
  "Please input active mobile number": "携帯電話番号を入力してください",
  "Please input email address": "メールアドレスを入力してください",
  "Please review your details below.": "予約内容をご確認ください",
  "I accept the Terms & Condition": "利用規約に同意する",
  "Terms and Conditions": "利用規約",
  "Reservation Complete": "予約完了",
  "Last Name": "苗字",
  "First Name": "ファーストネーム ",
  "Please input Last name": "姓を入力してください",
  "Please input First name": "名を入力してください",
  "Mobile Number should be 11 Digit": "電話番号を11桁で入力してください",
  Surname: "姓",
  Name: "名",
  "Preferred avatar for operator": "お好きなオペレーターを選択してください",
  "Preferred Avatar": "優先アバター",
  "Female 1": "女性1",
  "Female 2": "女性2",
  "Male 1": "男性1",
  "Male 2": "男性2",

  // Consult Now
  "Keep Looking": "Keep Looking",
  "Reserve specific date and time": "Reserve specific date and time",
  "Please wait while we find available operators to assist you":
    "Please wait while we find available operators to assist you",
  "Oh no! All our operators are busy at the moment.":
    "Oh no! All our operators are busy at the moment.",
  "Yay, we have found an operator!": "Yay, we have found an operator!",
  "This may take a while.": "This may take a while.",
  "Hold on. We are still looking.": "Hold on. We are still looking.",
  "Please expect a call once meeting reservation has been confirmed.":
    "Please expect a call once meeting reservation has been confirmed.",
};
