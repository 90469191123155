import React, { createRef } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Grid, Modal, Typography, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import "./styles.css";
import TermsAndConditionModalStates from "../../../interfaces/TermsAndConditionModal";
import PhoneEnabledIcon from "../PhoneEnabledIcon";

const APP_CALL_OPERATOR = process.env.REACT_APP_CALL_OPERATOR;
const APP_OPERATOR_PHONE_NUMBER = process.env.REACT_APP_OPERATOR_PHONE_NUMBER;
const CHATWEB = process.env.REACT_APP_PROMISE_CHAT;
const APP_CALL_OPERATOR_WOMEN = process.env.REACT_APP_CALL_OPERATOR_WOMEN;

interface Props extends RouteComponentProps<any> {
  modalvisible: any;
  closeSetState: Function;
  getRef: Function;
}

class HelpModal extends React.Component<Props, TermsAndConditionModalStates> {
  ref = createRef<any>();
  state: TermsAndConditionModalStates = {
    modalVisible: true,
  };

  componentDidMount() {
    this.props.getRef(this.ref);
  }

  handleCloseTermsAndConditionModal = () => {
    this.setState({ modalVisible: false });
    this.props.closeSetState();
    this.props.modalvisible(!this.state.modalVisible);
  };

  goToChat = () => {
    window.open(CHATWEB!.toString());
  };

  render() {
    return (
      <Grid container>
        <Modal open={this.state.modalVisible}>
          <Grid className="Reservation-Help-Card">
            <div
              className="Reservation-Help-close"
              onClick={() => {
                this.handleCloseTermsAndConditionModal();
              }}
            >
              ×
            </div>
            <div className="modalHelpDiv" ref={this.ref}>
              <div className="modalPhone">
                <Grid className="Reservation-Help-subcard-text-header">
                  <p className="Reservation-Help-free-call">チャットサービス</p>
                </Grid>
                <p className="Reservation-help-card-text">
                  チャットで気軽にご相談・お問合せができます。
                </p>
                <button
                  className="Reservation-help-chat"
                  onClick={() => {
                    this.goToChat();
                  }}
                >
                  チャットでお問合せ
                  <ArrowForwardIosIcon />
                </button>

                <div className="help-border-normal-first"></div>
              </div>

              <div className="modalPhone-card">
                <Button
                  className="modalPhone-card-text"
                  onClick={() => {
                    window.location.href = APP_CALL_OPERATOR
                      ? APP_CALL_OPERATOR
                      : "";
                  }}
                >
                  <div className="modalPhone-card-svg">
                    <ArrowForwardIosIcon />
                  </div>
                  <Grid className="Reservation-Help-subcard-text-header">
                    <p className="Reservation-Help-free-call">
                      ご相談・ご質問はプロミスコールへ
                    </p>
                  </Grid>
                  <p className="Reservation-help-card-text">
                    カード紛失・盗難についてのご連絡は24時間承ります。
                  </p>
                  <Grid xs={12} className="Reservation-help-phone-card-holder">
                    <PhoneEnabledIcon
                      className="Reservation-help-phone-icon"
                      fill="#00356c"
                    />
                    <div className="Reservation-help-phone-number">
                      {APP_OPERATOR_PHONE_NUMBER}
                    </div>
                  </Grid>
                  <Grid>
                    <Typography className="Reservation-help-time-text">
                      受付時間：平日9:00 - 18:00
                    </Typography>
                  </Grid>
                </Button>
              </div>
              <div className="help-border-normal"></div>

              <div className="modalPhone-card">
                <Button
                  className="modalPhone-card-text"
                  onClick={() => {
                    window.location.href = APP_CALL_OPERATOR_WOMEN
                      ? APP_CALL_OPERATOR_WOMEN
                      : "";
                  }}
                >
                  <div className="modalPhone-card-svg">
                    <ArrowForwardIosIcon />
                  </div>
                  <Grid className="Reservation-Help-subcard-text-header">
                    <div className="Reservation-Help-free-call">
                      女性専用ダイヤル レディースコール
                    </div>
                  </Grid>
                  <div className="Reservation-help-card-text">
                    女性オペレーターがお問合せを承ります。
                  </div>
                  <Grid xs={12} className="Reservation-help-phone-card-holder">
                    <PhoneEnabledIcon
                      className="Reservation-help-woman-icon"
                      fill="#ea4362"
                    />
                    <div className="Reservation-help-woman-number">
                      0120-86-2634
                    </div>
                  </Grid>
                  <Grid>
                    <Typography className="Reservation-help-time-text-last">
                      受付時間：平日9:00 - 18:00
                    </Typography>
                  </Grid>
                </Button>
              </div>
              <div className="help-border-no"></div>
            </div>
          </Grid>
        </Modal>
      </Grid>
    );
  }
}

export default withRouter(HelpModal);
