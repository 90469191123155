import React from "react";
import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { VideoCon } from "@ace-plus/core";

import { Grid, Typography, Card, Modal } from "@material-ui/core";

import { getCustomerReservationByMeetingID, getToken } from "../../api/index";

import IVirtualRoomStates from "../../interfaces/VirtualRoom";
import Thankyou from "../Thankyou";
import Female1 from "../../assets/images/Female1.png";
import Female2 from "../../assets/images/Female2.png";
import Male1 from "../../assets/images/Male1.png";
import Male2 from "../../assets/images/Male2.png";

import "./styles.css";
import CommonError from "../CommonError";
import GoogleTagManagerUtils from "../../utils/GoogleTagManagerUtils";

interface Props extends RouteComponentProps<any> {
  location: any;
}

class VideoConference extends Component<Props, IVirtualRoomStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isPortrait: window.innerHeight >= window.innerWidth,
      meetingID: "",
      name: "",
      dateToday: new Date(),
      screenHeight: 0,
      cancelModal: false,
      edit: true,
      reservedDate: "",
      chip: "",
      reservationDetails: "",
      vidCon: false,
      avatar: "",
      image: Female2,
      modalComponent: false,
      errorFlag: false,
      setToken: false,
      lastIsPortrait: window.innerHeight >= window.innerWidth,
      initScreenWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    GoogleTagManagerUtils.push({ event: "viewEnter" });
    this.getToken();
    let isPortrait;
    window.addEventListener("resize", () => {
      // Determine whether the screen is horizontal or vertical based on the current height and width.
      if (window.innerHeight < window.innerWidth) {
        isPortrait = false;
      } else {
        isPortrait = true;
      }
      // Prevents keyboard pop-ups while typing from causing misjudgment of screen orientation.
      if (
        this.state.lastIsPortrait &&
        window.innerWidth === this.state.initScreenWidth
      ) {
        isPortrait = true;
      }

      this.setState({
        initScreenWidth: window.innerWidth,
        isPortrait: isPortrait,
        lastIsPortrait: isPortrait,
      });
    });

    this.getReservation();
  }

  async getToken() {
    const token = await getToken();
    sessionStorage.setItem("accessToken", token.data.data.access_token);
    this.setState({ setToken: true });
  }

  getVideoCon() {
    try {
      if (this.state.setToken) {
        return (
          <VideoCon
            renderMeetingDetails={() => {
              return this.getMeetingDetails();
            }}
            renderFeedback={() => {
              return this.getThankyou();
            }}
            renderErrorPage={() => {
              return this.getErrorPage();
            }}
            localOrientation="top-right"
            disableChat={true}
            base_URL={process.env.REACT_APP_VIDEOCON_URL}
            localUser={{
              meetingID: this.getParameterByName("m"),
              name: "customer",
              role: "customer",
            }}
            locale="ja"
            gifURL={process.env.REACT_APP_ASSETS_URL}
          />
        );
      }
    } catch (e) {
      this.getErrorPage();
    }
  }

  getThankyou() {
    return <Thankyou />;
  }

  getErrorPage() {
    return <CommonError />;
  }

  getMeetingDetails() {
    if (this.state.errorFlag) {
      return <CommonError />;
    } else {
      return (
        <Grid container justifyContent="center" id="avatarCenter">
          <Grid item id="container" xs={12} className={"VirtualRoom-container"}>
            <img
              alt="avatar"
              src={this.state.image}
              className={"VirtualRoom-avatar-image"}
            />
          </Grid>
          <Card className={"VirtualRoom-card-body"} id="virtualRoomCard">
            <Typography className={"VirtualRoom-card-title"}>
              オンライン相談にお申込いただきありがとうございます。
            </Typography>
            <Typography className={"VirtualRoom-card-content"}>
              ご相談を開始するには、「入室する」ボタンを押してください。
            </Typography>
            <Typography className={"VirtualRoom-card-note"}>
              ※ボタンが押せるまでにお時間がかかる場合がございます。
            </Typography>
          </Card>
        </Grid>
      );
    }
  }
  getParameterByName(name: String, url = window.location.href) {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  handleAvatar = () => {
    if (this.state.avatar === "Female 1") {
      this.setState({ image: Female1 });
    }
    if (this.state.avatar === "Female 2") {
      this.setState({ image: Female2 });
    }

    if (this.state.avatar === "Male 1") {
      this.setState({ image: Male1 });
    }

    if (this.state.avatar === "Male 2") {
      this.setState({ image: Male2 });
    }
  };

  async getReservation() {
    try {
      const details = await getCustomerReservationByMeetingID(
        this.getParameterByName("m")
      );

      if (details.length !== 0) {
        var year = details[0].meeting_date.split("-")[0];
        var month = details[0].meeting_date.split("-")[1];
        var day = details[0].meeting_date.split("-")[2];
        const dayNum = Number(day);
        if (dayNum < 10) day = day.replace("0", "");
        var japdate = year + "年" + month.replace("0", "") + "月" + day + "日 ";
        this.setState({
          reservationDetails: details,
          reservedDate: japdate,
          chip: details[0].meeting_time,
          avatar: details[0].avatar,
        });
        this.handleAvatar();
      } else {
        this.setState({
          edit: false,
        });
      }
    } catch (e) {
      this.setState({
        errorFlag: true,
      });
    } finally {
    }
  }

  render() {
    const { isPortrait } = this.state;
    return (
      <Grid container style={{ height: window.innerHeight }}>
        <Modal className={"VirtualRoom-landscape"} open={!isPortrait}>
          <div className={"VirtualRoom-landscape-child"}>
            スマートフォンを縦にしてご利用ください。
          </div>
        </Modal>
        <Grid item id="container" xs={12} className={"VirtualRoom-container"}>
          {this.getVideoCon()}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(VideoConference);
