class Navigation {
  routes = [];

  getDefaultRoute() {
    return this.routes.find((r) => r.default).path;
  }

  getRoutes(type) {
    return this.routes.filter((route) => {
      let validate = false;
      switch (type) {
        case "public":
          validate = !route.protected;
          break;
        case "private":
          validate = route.protected;
          break;
        case "redirect":
          validate = route.redirect;
          break;
        default:
          validate = false;
          break;
      }
      return validate;
    });
  }
}

export default new Navigation();
