import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Translation } from "react-i18next";

import AppBar from "../Layout/AppBar";
import { Grid } from "@material-ui/core";
import { NoOperator } from "../../interfaces/NoOperator";
import EndPage from "../EndPage";

import Footer from "../Layout/Footer";

interface Props extends RouteComponentProps<any> {}
class CommonError extends React.Component<Props, NoOperator> {
  _isMounted = false;
  constructor(props: Props) {
    super(props);
    this.state = {
      holder: this?.props?.location?.state,
    };
  }
  contextOfEndPage = {
    title: "ご指定のページは表示できません",
    noticeInfo: "",
    content: "恐れ入りますが、SMSの招待用URLより再度お手続をお願いいたします。",
    buttonName: "",
    operatorPhoneNumber: false,
    spaceArea: true,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
  }

  render() {
    return (
      <Grid container className="Endpage-grid-container">
        <Grid item xs={12}>
          <AppBar appbarTitle={"Online Consultation"} withCallback={false} />
        </Grid>
        <EndPage {...this.contextOfEndPage} />
        <Footer />
      </Grid>
    );
  }
}

export default withRouter(CommonError);
