import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Grid, Modal, Typography } from "@material-ui/core";
import "./styles.css";

interface Props extends RouteComponentProps<any> {
    modalvisible: any;
}

class Loading extends React.Component<
    Props
> {
    state: any = {
        modalVisible: true,
    };

    handleCloseTermsAndConditionModal = () => {
        this.setState({ modalVisible: false });
        this.props.modalvisible(!this.state.modalVisible);
    };

    render() {
        return (
            <Grid container style={{ height: window.innerHeight }}>
                <Modal open={this.state.modalVisible} disableRestoreFocus={true}>
                    <Grid className="modal-loading">
                        <div className="modal-loading-div">
                            <div className="loader"></div>
                            <div className="loader-text">Loading...</div>
                        </div>
                    </Grid>
                </Modal>
            </Grid>
        );
    }
}

export default withRouter(Loading);
