import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Translation } from "react-i18next";

import AppBar from "../Layout/AppBar";
import { Grid } from "@material-ui/core";
import { NoOperator } from "../../interfaces/NoOperator";
import EndPage from "../EndPage";

import Footer from "../Layout/Footer";

import "./styles.css";

interface Props extends RouteComponentProps<any> {}
const customNet = process.env.REACT_APP_PROMISE_ADDRESS;
class NoAvailableOperator extends React.Component<Props, NoOperator> {
  _isMounted = false;
  constructor(props: Props) {
    super(props);
    this.state = {
      holder: this?.props?.location?.state,
    };
  }
  contextOfEndPage = {
    title: "オンライン相談のお申込",
    noticeInfo: "お申込は完了しておりません。",
    innerHTML:
      "申し訳ございませんが、オンライン相談サービスへのお申込が集中しているか、または受付時間外となります。<br/>" +
      "しばらくたってから再度アクセスしていただくか、受付時間内のご利用をお待ちしております。<br/><br/>" +
      "オンライン相談サービスの受付時間は、9時~18時までとなります。",
    buttonName: "戻る",
    displayButton: true,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
  }

  render() {
    return (
      <React.Fragment>
        <Translation>
          {(t) => (
            <Grid container>
              <Grid item xs={12}>
                <AppBar
                  appbarTitle={"Online Consultation"}
                  withCallback={false}
                />
              </Grid>
              <EndPage {...this.contextOfEndPage} />
              <Footer />
            </Grid>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}

export default withRouter(NoAvailableOperator);
