import { Box, Step, StepLabel, Stepper } from "@material-ui/core";
import React, { Component } from "react";


class StepBar extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            step: this.props.step
        };
    }

    handleStyleChange(){
        if(this.state.step === 0){
            return "progressbar-list"
        }else if(this.state.step === 1){
            return "progressbar-list2"
        }else if(this.state.step === 2){
            return "progressbar-list3"
        }
    }

    active2StyleChange(){
        if(this.state.step === 0){
            return "active2"
        }else{
            return "active2-2"
        }
    }

    active3StyleChange(){
        if(this.state.step === 2){
            return "active3-3"
        }else{
            return "active3"
        }
    }

    render() {
        return (
            <div className={this.handleStyleChange()}>
            <ul className="progressbar">
              <li className="active">お申込</li>
              <li className={this.active2StyleChange()}>確認</li>
              <li className={this.active3StyleChange()}>完了</li>
            </ul>
          </div>
            )

    }
}
export default StepBar;
