import i18n from "i18next";
import english from "../locales/en";
import japanese from "../locales/ja";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("language") === "ja" ? "ja" : "en",
    // we init with resources
    resources: {
      en: {
        translations: english,
      },
      ja: {
        translations: japanese,
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
