import CSS from "csstype";

export const reservationModalHeader: CSS.Properties = {
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "18px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#E8340C",
  // marginLeft:"50px",
  marginTop: "10px",
  fontFamily:
    "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ, Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif",
};

export const reservationModalContent: CSS.Properties = {
  // alignItems: "center",
  // textAlign: 'center',
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "21px",
  display: "flex",
  whiteSpace: "normal",
  // marginLeft:"50px",
  marginTop: "15px",
  fontFamily:
    "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ, Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif",
};

export const reservationSuccessModal: CSS.Properties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "transparent",
};

export const reservationSuccessModalCardContainer: CSS.Properties = {
  backgroundColor: "#fff",
  height: "220px",
  width: "300px",
  borderRadius: "5px",
};

export const reservationSuccessModalGoToHomePageButton: CSS.Properties = {
  width: "260px",
  height: "40px",
  // backgroundColor: "#003D7B",
  color: "#fff",
  // border: "#003D7B",
  textTransform: "capitalize",
  fontSize: "13px",
  marginTop: "15px",
  marginLeft: "6px",
  borderRadius: "8px",
  fontFamily:
    "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ, Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif",
};

export const reservationCancelMessageModal: CSS.Properties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "transparent",
};

export const reservationCancelMessageModalCardContainer: CSS.Properties = {
  backgroundColor: "#fff",
  height: "220px",
  width: "300px",
  borderRadius: "5px",
};

export const reservationCancelMessageModalGoBackButton: CSS.Properties = {
  width: "90px",
  height: "40px",
  backgroundColor: "#FFFFFF",
  // color: "#003D7B",
  // border: "1px solid #003D7B  ",
  fontSize: "13px",
  textTransform: "capitalize",
  marginTop: "15px",
  marginLeft: "15px",
  borderRadius: "8px",
  fontFamily:
    "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ, Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif",
};

export const reservationCancelMessageModalCancelChangesButton: CSS.Properties =
  {
    width: "140px",
    height: "40px",
    // backgroundColor: "#003D7B",
    color: "#fff",
    // border: "#003D7B",
    textTransform: "capitalize",
    fontSize: "13px",
    marginTop: "15px",
    marginLeft: "10px",
    borderRadius: "8px",
    fontFamily:
      "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ, Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif",
  };
