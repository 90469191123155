import React from "react";

import Appbar from "../Layout/AppBar";
import { withRouter } from "react-router-dom";
import i18n from "../../lib/i18n";

import {
  Button,
  Grid,
  Card,
  CardContent,
  Modal,
  Typography,
} from "@material-ui/core";
import "fontsource-mitr";
import "./reservation.css";

import {
  checkWithinOfficeHours,
  getAvailabilityOfAgents,
  getNumberOfUnassignedReservation,
} from "../../api";

// utils
import { isMobileUserAgent } from "../../utils/deviceDetection";

// interface
import { Props, customerScheduleState } from "../../interfaces/Reservation";

// styles
import * as styles from "./styles";

// reservation component
import ReservationForm from "./ReservationForm";

import Footer from "../Layout/Footer";
import Loading from "../Common/Loading";

class ScheduleConsultation extends React.Component<
  Props,
  customerScheduleState
> {
  constructor(props: Props) {
    super(props);
    this.firstMobileComponent = React.createRef();
    this.secondMobileComponent = React.createRef();
    this.thirdMobileComponent = React.createRef();
  }
  firstMobileComponent: any;
  secondMobileComponent: any;
  thirdMobileComponent: any;

  state: customerScheduleState = {
    language: localStorage.getItem("language") === "ja" ? "ja" : "en",
    API_URL: process.env.REACT_APP_RESTAPI_URL!.toString(),
    consultationDetails: true,
    basicCustomerInfo: false,
    questions: false,
    uploadFiles: false,
    cancelModal: false,
    confirmModal: false,
    readysetTime: false,
    setStartTime: "",
    setEndTime: "",
    enteredBdate: false,
    getAge: 1,
    meetingID: "",
    dateToday: new Date(),
    noAvailableOperator: false,
    agentsInPreparing: false,
    isLoading: true,
    availableAgents: 0,
    inPreparingAgents: 0
  };

  componentDidMount() {
    if (!isMobileUserAgent()) {
      this.props.history.push("/error");
    } else {
      this.checkAvailableOperators();
    }
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps() {
    this.setState({ language: localStorage.getItem("language") }, () => {
      console.log(" : ", this.state.language);
    });
  }

  handleCloseConfirmModalForSubmittedReservation = () => {
    this.setState({ confirmModal: false });
    this.props.history.push({
      pathname: "/home",
    });
  };

  handleShowCancelModal() {
    this.setState({ cancelModal: true });
  }

  handleCloseCancelModal = () => {
    this.setState({ cancelModal: false });
  };

  async checkAvailableOperators() {
    try {
      const result = await checkWithinOfficeHours();
      if (result.withinOfficeHours) {
        const unassignedReservation = await getNumberOfUnassignedReservation();
        const data = await getAvailabilityOfAgents();
        this.checkOperatorStatus(
          data.result,
          unassignedReservation.numberOfReservationOnQueue.message
        );
      } else {
        this.setState({ noAvailableOperator: true, isLoading: false });
      }
    } catch (e) {
      console.log("Error: ", e);
      this.setState({ noAvailableOperator: true, isLoading: false });
    }
  }

  checkOperatorStatus(agents: any, unassignedReservations: any) {
    if (
      (agents.agentsInAvailable === 0 && agents.agentsInPreparing === 0) ||
      agents.agentsInAvailable + agents.agentsInPreparing <=
        unassignedReservations
    ) {
      this.setState({ noAvailableOperator: true, isLoading: false });
    } else if (agents.agentsInPreparing > 0) {
      this.setState({ agentsInPreparing: true, isLoading: false, inPreparingAgents: agents.agentsInPreparing});
    } else {
      this.setState({ isLoading: false, availableAgents: agents.agentsInAvailable });
    }
  }

  render() {
    const locale = localStorage.getItem("language") || "ja";

    return (
      <React.Fragment>
        {this.state.isLoading === false ? (
          <Grid container>
            <Grid item xs={12}>
              <Appbar
                appbarTitle={"Online Consultation"}
                withCallback={false}
              />
            </Grid>
            <Grid item xs={12}>
              {this.state.consultationDetails === true ? (
                <ReservationForm
                  handleShowCancelModal={this.handleShowCancelModal.bind(this)}
                  holder={this.props}
                  noAvailableOperator={this.state.noAvailableOperator}
                  agentsInPreparing={this.state.agentsInPreparing}
                  inPreparingAgents={this.state.inPreparingAgents}
                  availableAgents={this.state.availableAgents}
                ></ReservationForm>
              ) : (
                ""
              )}
            </Grid>

            <Footer />

            <Modal
              open={this.state.confirmModal}
              onClose={this.handleCloseConfirmModalForSubmittedReservation}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={styles.reservationSuccessModal}
            >
              <Card style={styles.reservationSuccessModalCardContainer}>
                <CardContent>
                  <Grid container justify="center">
                    <Grid item xs={12}>
                      <Typography style={styles.reservationModalHeader}>
                        {i18n.t(
                          "Online Consultation is Successfully Reserved!",
                          {
                            lng: locale,
                          }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={styles.reservationModalContent}>
                        {i18n.t(
                          "The content of the created online consultation was sent by short message.",
                          { lng: locale }
                        )}
                      </Typography>
                    </Grid>

                    <Button
                      style={styles.reservationSuccessModalGoToHomePageButton}
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/home",
                        })
                      }
                    >
                      {i18n.t("Go To HomePage", { lng: locale })}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Modal>

            <Modal
              open={this.state.cancelModal}
              onClose={this.handleCloseCancelModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={styles.reservationCancelMessageModal}
            >
              <Card style={styles.reservationCancelMessageModalCardContainer}>
                <CardContent>
                  <Grid container justify="center">
                    <Grid item xs={12}>
                      <Typography style={styles.reservationModalHeader}>
                        {i18n.t(
                          "Are you sure you want to cancel your schedule?",
                          { lng: locale }
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={styles.reservationModalContent}>
                        {i18n.t(
                          "All your updates will not be saved if you click ‘Cancel Changes’",
                          { lng: locale }
                        )}
                      </Typography>
                    </Grid>
                    <Button
                      style={styles.reservationCancelMessageModalGoBackButton}
                      onClick={() => {
                        this.handleCloseCancelModal();
                      }}
                    >
                      {i18n.t("Go Back", { lng: locale })}
                    </Button>

                    <Button
                      style={
                        styles.reservationCancelMessageModalCancelChangesButton
                      }
                      onClick={() => this.props.history.push("/homepage")}
                    >
                      {i18n.t("Cancel Changes", { lng: locale })}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Modal>
          </Grid>
        ) : (
          <Loading modalvisible={true} />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ScheduleConsultation);
