import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Translation } from "react-i18next";

import { Grid, Button } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import AppBar from "../Layout/AppBar";

import IConfirmationStates from "../../interfaces/confirmation";

import OperatorPhoneNumber from "../OperatorPhoneNumber";

import Footer from "../Layout/Footer";

import "./styles.css";
import StepBar from "../Common/StepBar";
import Warning from "@material-ui/icons/Warning";
import { Steps } from "../../utils/constants";

interface Props extends RouteComponentProps<any> {}
// StepBar設定
const steps = {
  step: 2,
};
const customNet = process.env.REACT_APP_PROMISE_ADDRESS;

class Confirmation extends React.Component<Props, IConfirmationStates> {
  _isMounted = false;
  constructor(props: Props) {
    super(props);
    this.state = {
      hasAvailableOperator: this?.props?.location?.state,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
  }

  goToHome() {
    window.location.href = customNet!.toString();
  }

  topFunction = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <React.Fragment>
        <Translation>
          {(t, { i18n }) => (
            <Grid container>
              <Grid item xs={12}>
                <AppBar
                  appbarTitle={"Online Consultation"}
                  withCallback={false}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="ReservationForm-form">
                  <div className="ReservationForm-Title">
                    オンライン相談のお申込 完了
                  </div>
                  <StepBar {...steps}></StepBar>
                  <Grid className="Confirmation-text">
                    <Grid className="Confirmation-header-holder">
                      <div className="Confirmation-sms-text">
                        このたびは、お申込いただきありがとうございます。
                      </div>
                      <div>お申込が完了しました。</div>

                      {this.state.hasAvailableOperator.hasAvailableOperator ? (
                        <div className="Confirmation-sms-text">
                          SMSにて招待用URLをお送りいたします。しばらくお待ちください。
                        </div>
                      ) : (
                        <div className="Confirmation-sms-text">
                          SMSにて招待用URLを10分前後でお送りいたします。しばらくお待ちください。
                        </div>
                      )}

                      <div>SMSが届きましたら、URLから入室してください。</div>

                      <div className="Confirmation-sms-text-below">
                        <Warning />
                        SMSが届かない場合は、お手数ですがプロミスコールまでお問合せください。
                      </div>
                    </Grid>
                  </Grid>

                  <OperatorPhoneNumber />

                  <button
                    className="ReservationForm-returnButton"
                    onClick={() => {
                      this.goToHome();
                    }}
                  >
                    HOMEへ戻る
                  </button>
                  <button
                    onClick={() => this.topFunction()}
                    className="ReservationForm-backToTopButton"
                  >
                    <ArrowUpwardIcon />
                    　このページのTOPへ戻る
                  </button>
                </div>
              </Grid>
              <Footer />
            </Grid>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}

export default withRouter(Confirmation);
