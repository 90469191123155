import React, { Component } from "react";
class DateSelect extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      yearList: props.yearList,
      targetYearList: props.targetYearList,
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dayList: props.dayList,
      selectYear: this.props.selectYear,
      selectMonth: this.props.selectMonth,
      selectDay: this.props.selectDay,
      disableFlag: this.props.disableFlag,
      selectYearFlag: this.props.selectYearFlag,
      selectMonthFlag: this.props.selectMonthFlag,
      selectDayFlag: this.props.selectDayFlag,
      selectedY: false,
      firstSeletY: this.props.firstSeletY,
      firstSeletM: this.props.firstSeletM,
      firstSeletD: this.props.firstSeletD,
    };
    this.dataInit = this.dataInit.bind(this);
  }
  // Component初期化
  componentDidMount() {
    this.dataInit();
  }

  //生年月日リスト作成
  dataInit() {
    const dateTime = new Date();
    const yearList = Array.from(
      { length: 58 },
      (_a, i) => i + dateTime.getFullYear() - 75
    );
    const targetYearList: any = [];
    for (let i = 0; i < yearList.length; i++) {
      if (yearList[i] === 1989) {
        targetYearList.push(`${yearList[i]}(昭和64)`);
      }
      if (yearList[i] === 2019) {
        targetYearList.push(`${yearList[i]}(平成31)`);
      }
      targetYearList.push(
        `${yearList[i]}(${this.en2JpYearForamtConvert(yearList[i], 11, 31)})`
      );
    }
    let dayList: any = [];
    new Promise<void>((resolve, reject) => {
      for (let k = 1; k < 32; k++) {
        dayList.push(k);
      }
      resolve();
    }).then((...e) => {
      this.setState({
        targetYearList,
        yearList,
        dayList,
      });
    });
  }

  // 年変更
  yearChange(e: any) {
    let curYear = e.target.value.substring(0, 4);
    if (this.state.selectMonth) {
      let curMonth = this.state.selectMonth;
      let leapYearFlag = false;
      let dayList = [];
      let minDaysMonth = ["4", "6", "9", "11"];
      if ((curYear % 4 === 0 && curYear % 100 !== 0) || curYear % 400 === 0) {
        leapYearFlag = true;
      }
      if (leapYearFlag && curMonth === "2") {
        dayList = this.getDayArrayWithoutZero(29);
        if (this.state.selectDay > 29) {
          this.setState(
            {
              selectDay: 29,
            },
            () => {
              this.props.getdata(this.state);
            }
          );
        }
      } else if (curMonth === "2") {
        dayList = this.getDayArrayWithoutZero(28);
        if (this.state.selectDay > 28) {
          this.setState(
            {
              selectDay: 28,
            },
            () => {
              this.props.getdata(this.state);
            }
          );
        }
      } else {
        if (minDaysMonth.includes(curMonth)) {
          dayList = this.getDayArrayWithoutZero(30);
          if (this.state.selectDay > 30) {
            this.setState(
              {
                selectDay: 30,
              },
              () => {
                this.props.getdata(this.state);
              }
            );
          }
        } else {
          dayList = this.getDayArrayWithoutZero(31);
        }
      }
      this.setState(
        {
          selectYear: e.target.value,
          dayList: dayList,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    } else {
      this.setState(
        {
          selectYear: e.target.value,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    }
  }

  getDayArrayWithoutZero(days: any) {
    return Array.from({ length: days }, (_a, i) => (i + 1).toString());
  }
  // 月変更
  monthChange(e: any) {
    let curMonth = e.target.value;
    if (this.state.selectYear) {
      let curYear = this.state.selectYear.substring(0, 4);
      let leapYearFlag = false;
      let dayList = [];
      let minDaysMonth = ["4", "6", "9", "11"];
      if ((curYear % 4 === 0 && curYear % 100 !== 0) || curYear % 400 === 0) {
        leapYearFlag = true;
      }
      if (leapYearFlag && curMonth === "2") {
        dayList = this.getDayArrayWithoutZero(29);
        if (this.state.selectDay > 29) {
          this.setState(
            {
              selectDay: 29,
            },
            () => {
              this.props.getdata(this.state);
            }
          );
        }
      } else if (curMonth === "2") {
        dayList = this.getDayArrayWithoutZero(28);
        if (this.state.selectDay > 28) {
          this.setState(
            {
              selectDay: 28,
            },
            () => {
              this.props.getdata(this.state);
            }
          );
        }
      } else {
        if (minDaysMonth.includes(curMonth)) {
          dayList = this.getDayArrayWithoutZero(30);
          if (this.state.selectDay > 30) {
            this.setState(
              {
                selectDay: 30,
              },
              () => {
                this.props.getdata(this.state);
              }
            );
          }
        } else {
          dayList = this.getDayArrayWithoutZero(31);
        }
      }

      this.setState(
        {
          selectMonth: e.target.value,
          dayList: dayList,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    } else {
      this.setState(
        {
          selectMonth: e.target.value,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    }
  }

  // 日変更
  dayChange(e: any) {
    let day = e.target.value;
    this.setState(
      {
        selectDay: day,
      },
      () => {
        this.props.getdata(this.state);
      }
    );
  }

  // 年が西暦から和暦に変換する
  en2JpYearForamtConvert(year: any, month = 11, day = 31) {
    const date = new Date();
    date.setFullYear(year, month, day);
    let japaneseCalendar = date.toLocaleDateString("ja-JP-u-ca-japanese", {
      era: "long",
      year: "numeric",
    });

    const beforeYearStr = Number(
      japaneseCalendar.substring(
        japaneseCalendar.lastIndexOf("1年") - 1,
        japaneseCalendar.lastIndexOf("1年")
      )
    );

    if (japaneseCalendar && (isNaN(beforeYearStr) || beforeYearStr === 0)) {
      japaneseCalendar = japaneseCalendar.replace("1年", "元年");
    }

    if (
      japaneseCalendar &&
      (navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1)
    ) {
      const realJapaneseCalendar = this.en2JpYearFormatConvertAndroid(
        year,
        japaneseCalendar
      );
      return realJapaneseCalendar.substring(
        0,
        realJapaneseCalendar.lastIndexOf("年")
      );
    }

    return japaneseCalendar.substring(0, japaneseCalendar.lastIndexOf("年"));
  }

  // 年が西暦から和暦に変換する(手動で和暦に処理)
  en2JpYearFormatConvertAndroid(year: any, japaneseCalendar: any) {
    if (japaneseCalendar.length >= 4) {
      return japaneseCalendar;
    }
    if (japaneseCalendar.indexOf("元年") !== -1 && year === 1989) {
      return "平成" + japaneseCalendar;
    }
    if (japaneseCalendar.indexOf("元年") !== -1 && year === 2019) {
      return "令和" + japaneseCalendar;
    }
    if (1926 <= year && year <= 1989) {
      return "昭和" + japaneseCalendar;
    }
    if (1989 < year && year <= 2019) {
      return "平成" + japaneseCalendar;
    }
    if (year > 2019) {
      return "令和" + japaneseCalendar;
    }
  }
  // はじめ選択年
  selectYears() {
    if (!this.state.selectYearFlag) {
      this.setState(
        {
          selectedY: true,
          selectYear: this.state.targetYearList[41],
          selectYearFlag: true,
          firstSeletY: true,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    } else {
      return;
    }
  }

  // はじめ選択月
  selectMonth() {
    if (!this.state.selectMonthFlag) {
      this.setState(
        {
          selectMonth: this.state.monthList[0],
          selectMonthFlag: true,
          firstSeletM: true,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    } else {
      return;
    }
  }
  // はじめ選択日
  selectDay() {
    if (!this.state.selectDayFlag) {
      this.setState(
        {
          selectDay: this.state.dayList[0],
          selectDayFlag: true,
          firstSeletD: true,
        },
        () => {
          this.props.getdata(this.state);
        }
      );
    } else {
      return;
    }
  }
  // 生年月日の仕様
  handleSelectYearStyleChange = () => {
    let className = "select-year";
    if (this.props.disableFlag) {
      className = "select-year-disabled";
    } else if (this.props.errorFlagY) {
      if (this.state.firstSeletY) {
        className = "select-year-error";
      } else {
        className = "select-year-error-first";
      }
    } else {
      if (this.state.firstSeletY) {
        if (this.state.selectYear) {
          className = "select-year-value";
        } else {
          className = "select-year";
        }
      } else {
        className = "select-year-first";
      }
    }
    return className;
  };
  handleSelectMonthStyleChange = () => {
    let className = "select-main";
    if (this.props.disableFlag) {
      className = "select-main-disabled";
    } else if (this.props.errorFlagM) {
      if (this.state.firstSeletM) {
        className = "select-main-error";
      } else {
        className = "select-main-error-first";
      }
    } else {
      if (this.state.firstSeletM) {
        if (this.state.selectMonth) {
          className = "select-main-value";
        } else {
          className = "select-main";
        }
      } else {
        className = "select-main-first";
      }
    }
    return className;
  };
  handleSelectDayStyleChange = () => {
    let className = "select-main";
    if (this.props.disableFlag) {
      className = "select-main-disabled";
    } else if (this.props.errorFlagD) {
      if (this.state.firstSeletD) {
        className = "select-main-error";
      } else {
        className = "select-main-error-first";
      }
    } else {
      if (this.state.firstSeletD) {
        if (this.state.selectDay) {
          className = "select-main-value";
        } else {
          className = "select-main";
        }
      } else {
        className = "select-main-first";
      }
    }
    return className;
  };

  render() {
    return (
      <div className="ReservationForm-form-input">
        <div className="select-box">
          <div className="selectArrow" />
          <select
            id="select-date"
            onChange={this.yearChange.bind(this)}
            className={this.handleSelectYearStyleChange()}
            value={this.state.selectYear}
            disabled={this.state.disableFlag}
            onClick={() => this.selectYears()}
          >
            <option
              className="li-item"
              style={{ display: "none" }}
              value={""}
            ></option>
            {this.state.targetYearList
              ? this.state.targetYearList.map((item: any, index: any) =>
                  index === 41 && !this.state.firstSeletY ? (
                    <option
                      value={item}
                      key={index}
                      className="li-item"
                      selected
                    >
                      {item}
                    </option>
                  ) : (
                    <option value={item} key={index} className="li-item">
                      {item}
                    </option>
                  )
                )
              : ""}
          </select>
        </div>

        <div className="select-box-month">
          <div className="selectArrowMonth" />
          <select
            onChange={this.monthChange.bind(this)}
            className={this.handleSelectMonthStyleChange()}
            value={this.state.selectMonth}
            disabled={this.state.disableFlag}
            onClick={() => this.selectMonth()}
          >
            <option
              className="li-item"
              style={{ display: "none" }}
              value={""}
            ></option>
            {this.state.monthList
              ? this.state.monthList.map((item: any, index: any) =>
                  index === 0 ? (
                    <option
                      value={item}
                      key={index}
                      className="li-item"
                      selected
                    >
                      {item}
                    </option>
                  ) : (
                    <option value={item} key={index} className="li-item">
                      {item}
                    </option>
                  )
                )
              : ""}
          </select>
        </div>

        <div className="select-box-day">
          <div className="selectArrowDay" />
          <select
            onChange={this.dayChange.bind(this)}
            className={this.handleSelectDayStyleChange()}
            value={this.state.selectDay}
            disabled={this.state.disableFlag}
            onClick={() => this.selectDay()}
          >
            <option
              className="li-item"
              style={{ display: "none" }}
              value={""}
            ></option>

            {this.state.dayList
              ? this.state.dayList.map((item: any, index: any) =>
                  index === 0 ? (
                    <option
                      value={item}
                      key={index}
                      className="li-item"
                      selected
                    >
                      {item}
                    </option>
                  ) : (
                    <option value={item} key={index} className="li-item">
                      {item}
                    </option>
                  )
                )
              : ""}
          </select>
        </div>
      </div>
    );
  }
}
export default DateSelect;
