import TagManager from "../lib/googleTagManager";

export default {
  init: function (): boolean {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      });
      return true;
    }
    return false;
  },

  push: function (obj: {}) {
    TagManager.dataLayer({
      dataLayer: obj,
    });
  },
};
