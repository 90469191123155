export default {
  // TODO: Remove unused locale fields

  sample2: `Explanation of Important Matters
The document "Explanation of Important Matters" contains matters that customers should check in order to understand the contents of insurance products and matters that require special attention when making a contract. Please confirm and receive it.
Above all, please pay particular attention to the points where there is a disadvantage to the customer, such as in the case of not paying insurance money.
If the customer and the insured person are different, we ask that the customer also explain the details in this document to the insured person.
In the unlikely event of an accident, the accident reception desk is listed, so please contact us immediately.`,

  "START USING REMOTE SALES": "START USING REMOTE SALES",
  // Video Call
  "Meeting Details": "MEETING DETAILS",
  "John Doe": "John Doe",
  Agent: "Agent",
  "Meeting Agenda": "Meeting Agenda",
  "Values for Agenda": "Motor Insurance renewal",
  Schedule: "Schedule",
  "Entering Message":
    "You can enter the meeting 00 minutes before the meeting starts",
  "Join Meeting": "Join Meeting",
  "Video Call": "VIDEO CALL",
  Messages: "MESSAGES",
  Username: "Username",
  "Enter username": "Enter username",
  "Customer Brain Login": "CUSTOMER LOGIN",
  "E-mail address": "E-mail address",
  "Enter E-mail address": "Enter E-mail address",
  Password: "Password",
  "Enter Password": "Enter Password",
  Login: "Login",
  "Sign in": "SIGN IN",
  "Sign in Message":
    "This is the screen to start using the dedicated communication service with agents. If you have login information for our customer page, please enter it and start using it.",
  "Email error": "Email Address does not exist!",
  "Notice Title": "※Notes※",
  "Notice Description 1":
    "Audio and video recording at the time of consultation is prohibited, and disclosure to SNS is also prohibited.",
  "Notice Description 2":
    "At the time of consultation, it is recommended to secure a place where personal information cannot be seen or heard by others (other than second degree), earphones, etc.​",
  "Notice Description 2.5": "",
  // "Password error": "Incorrect Password!",
  "Password error": "Incorrect username or password!",
  Name: "Name",
  "Meeting ID": "Meeting ID",
  Continue: "Continue",
  Connecting: "Connecting...",
  "Waiting for others to join": "Waiting for the others to join...",
  "Entering to Conference room": "Entering to Conference room. Please wait...",
  "Preparing devices": "Preparing devices...",
  "Connecting to Conference Session": "Connecting to Conference Session...",
  //homepage
  "MS1 Customer Brain": "REMOTE SALES CUSTOMER",
  "Agency Chat": "Agency Chat",
  "Agency Chats": "Agency Chats",
  "Accident Contact": "Accident Contact",
  "Change Procedure": "Change Procedure",
  "Agency Information": "Agency Information",
  "Contract Continuation": "Contract Continuation  6/25 13:00 - 14:30",
  "Schedule Consultation": "Schedule Consultation",
  Meetings: "Meetings",
  "Need Help?": "Need Help?",
  Home: "Home",
  returnHome: "Home",
  Chat: "Chat",
  Notifications: "Notifications",
  Search: "Search...",
  To: "To:",
  Select: "Select",
  "No Available Data": "No Available Data",
  "Write your reply here": "Write your reply here...",
  "My Calendar": "My Calendar",
  "Application Confirmation": "Application Confirmation",
  "Name of the person to be procedure (in kanji)":
    "Name of the person to be procedure (in Kanji)",
  "Insurance Contractor": "Insurance Contractor",
  "Name of the person to be procedure (in Kana)":
    "Name of the person to be procedure (in Kana)",
  "Date of procedure": "Date of Procedure",
  "Name of agent": "Name of Agent",
  "Mail Address": "Mail Address",
  "Mail Address (re-entry)": "Mail Address (re-entry)",
  "Check the content and move on": "Check the content and move on",
  //plan
  "Select Plan": "Select Plan",
  "Insurance Type": "Insurance Type",
  "Conditions of Contract": "Conditions of Contract",
  "Limited driver": "Limited driver",
  "Condition of driver's age": "Condition of driver's age",
  "Purpose of use": "Purpose of use",
  "Damages to the other Person": "Damages to the other Person",
  "Bodily Injury (per person)": "Bodily Injury (per person)",
  "Property Damage Liability/Deductible (per accident)":
    "Property Damage Liability/Deductible (per accident)",
  Rider: "Rider",
  "Injury coverage": "Injury Coverage",
  "Personal Injury": "Personal Injury",
  "Coverage (per person)": "Coverage (per person)",
  "Rider and Road Service": "Rider and Road Service",
  "Road service costs": "Road service costs",
  "Road service": "Road service",
  "Other coverage and special riders": "Other coverage and special riders",
  "Other coverage and special riders (Indicates the name of the indemnity/specialty to be set)":
    "Other coverage and special riders (Indicates the name of the indemnity/specialty to be set)",
  "Other Rider": "Other Rider",
  "Discounts, premiums, etc.": "Discounts, premiums, etc.",
  "Car Insurance": "Car Insurance",
  "Vehicle Insurance": "Vehicle Insurance",
  "Type of Contract": "Type of Contract",
  "Vehicle insurance amount": "Vehicle insurance amount",
  Deductible: "Deductible",

  //view policy
  "Please select how you would like to view the policy and policy leaflet (policy conditions) from one of the following options.":
    "Please select how you would like to view the policy and policy leaflet (policy conditions) from one of the following options.",
  "Insurance policy: web browsing only. Terms of insurance policy: Web browsing only":
    "Insurance policy web browsing only. Terms of insurance policy Web browsing only",
  "Insurance policy: delivered on paper. Terms of insurance policy: Web browsing only":
    "Insurance policy delivered on paper. Terms of insurance policy Web browsing only",
  "Insurance policy: delivered on paper. Terms of insurance policy: delivered on paper":
    "Insurance policy delivered on paper. Terms of insurance policy delivered on paper",
  "To Confirmation of Procedures": "To Confirmation of Procedures",
  "How to view your insurance policy": "How to view your insurance policy",

  //important matter
  "Display 'Important Information'": "Display 'Important Information'",
  "View 'Confirmation Guide'": "View 'Confirmation Guide'",
  "We have received and reviewed the 'Explanation of Important Matters'":
    "We have received and reviewed the 'Explanation of Important Matters'.",
  "Explanation of important matter": "Explanation of Important Matter",

  //Reconfirmation
  Reconfirmation: "Reconfirmation",
  "Period of Insurance": "Period of Insurance",
  "How to pay insurance premiums": "How to pay insurance premiums",
  "Name of the applicant (policyholder)":
    "Name of the Applicants (policyholder)",
  "registered insured person": "Registered Insured Person",
  "Color of the license of the named insured":
    "Color of the License of the named insured",
  "registration number": "Registration Number",
  "chassis number": "Chassis number",
  "Vehicle type": "Vehicle Type",

  //summary
  "Place of Procedure": "Place of Procedure",
  "Date of Procedure": "Date of Procedure",
  "Name of Agent": "Name of Agent",
  "Final Confirmation": "Final Confirmation",
  Application: "Application",

  //Getting started
  "Shortcut Settings": "Shortcut Settings",
  "On the home screen of the smartphone, it is convenient to put a shortcut. You can set a Remote Sales Customer App shortcut on the home screen of your smartphone.":
    "On the home screen of the smartphone, it is convenient to put a shortcut. You can set a Remote Sales Customer App shortcut on the home screen of your smartphone.",
  "Getting Started": "Getting Started",

  //Shortcut settings
  "Customer Brain Shortcut Setting": "Customer Shortcut Setting",
  "Click on “More Options” on the Chrome Browser.":
    "Click on “More Options” on the Chrome Browser.",
  "Locate and Select “Add to Home Screen”":
    "Locate and Select “Add to Home Screen”",
  "Edit Description (as needed)": "Edit Description (as needed)",
  "Shortcut should appear on home screen now.":
    "Shortcut should appear on home screen now.",

  // google translate for the meantime
  "Application Procedure": "Application Procedure",
  "Please select a plan for your preference":
    "Please select a plan for your preference",
  "Plan 1": "Plan 1",
  "Plan 2": "Plan 2",
  "Plan 3": "Plan 3",
  Premium: "Premium",
  "Semi-Premium": "Semi-premium",
  Regular: "Regular",
  "Total  ¥17,600": "Total ¥17,600",
  "Plan Confirmation": "Plan Confirmation",
  "View Policy": "View Policy",
  Yes: "Yes",
  No: "No",
  "Summary Details": "Summary Details",
  "Applicant's Home": "Applicant's Home",
  "Applicant's Office": "Applicant's Office",
  "Agency Office": "Agency Office",
  Others: "Others",
  "No Available Schedule": "No Available Schedule",
  "Get Started": "Get Started",
  "Click on “More Options” at the bottom page of Microsoft Edge Browser.":
    "Click on “More Options” at the bottom page of Microsoft Edge Browser.",
  "Click on “More Options” on the Mozilla Firefox Browser.":
    "Click on “More Options” on the Mozilla Firefox Browser.",
  "Locate and Select “Page” and “Add Page Shortcut”":
    "Locate and Select “Page” and “Add Page Shortcut”",
  "Select your preferred browser to save the shortcut":
    "Select your preferred browser to save the shortcut",

  //Pension Modal
  "Register Pension": "Register Pension",
  "Old Age Pension Claim Information Registration":
    "Old Age Pension Claim Information Registration",
  Bank: "Bank",
  Branch: "Branch",
  "Account Holder": "Account Holder",
  Type: "Type",
  "Account Number": "Account Number",
  "Date of Start Receiving Pension": "Date of Start Receiving Pension",

  "Mizuho Bank": "Mizuho Bank",
  "Japan Post Bank": "Japan Post Bank",
  "Akasaka Branch": "Akasaka Branch",
  "Suginami Branch": "Suginami Branch",
  "Ichiro Suzuki": "Ichiro Suzuki",
  "Saving Account": "Saving Account",
  "Checking Account": "Checking Account",

  Register: "Register",
  Cancel: "Cancel",

  //Schedule Consultation

  "Consultation Details": "Consultation Details",
  "Please choose your preferred consultation date":
    "Please choose your preferred consultation date",
  "Please choose your preferred consultation time":
    "Please choose your preferred consultation time",
  "Please choose your preferred interview method":
    "Please choose your preferred interview method",
  "Cancel Consultation": "Cancel Consultation",
  "Proceed to Next Step": "Proceed to Next Step",
  "Start Time": "Start Time",
  "End Time": "End Time",
  "Basic Customer Information": "Basic Customer Information",
  Questions: "Questions",
  "Confirm Consultation": "Confirm Consultation",
  "Schedule Consultation Success!": "Schedule Consultation Success!",
  "You have successfully scheduled a consultation with an agent.":
    "You have successfully scheduled a consultation with an agent.",
  "Go To Homepage": "Go To Homepage",
  "Are you sure you want to cancel scheduling a consultation?":
    "Are you sure you want to cancel scheduling a consultation?",
  "You will not be able to save your scheduled consultation if you choose ‘Cancel Changes’":
    "You will not be able to save your scheduled consultation if you choose ‘Cancel Changes’",
  "Go Back": "Go Back",
  "Cancel Changes": "Cancel Changes",

  "Consultation on old-age pension": "Consultation on old-age pension",
  "Consultation on disability pension": "Consultation on disability pension",
  "Consultation on survivor's pension": "Consultation on survivor's pension ",

  "Web Interview": "Web Interview",
  "Visit Interview": "Visit Interview",

  Ruby: "Ruby",
  "Full Name": "Full Name​",
  "Consultation Content": "Consultation Content",
  "Basic Pension Number": "Basic Pension Number",
  "Date of Birth": "Date of Birth",
  "Consultation date and time": "Consultation date and time",

  " years old": " years old",

  "Do you receive an old age pension claim?":
    "Do you receive an old age pension claim?",
  "Do you currently have a spouse who is married in the family register?":
    "Do you currently have a spouse who is married in the family register?",
  "Do you have a welfare pension for more than 20 years?":
    "Do you have a welfare pension for more than 20 years?",
  "Is your spouse's annual income less than 8.5 million yen?":
    "Is your spouse's annual income less than 8.5 million yen?",
  "Is your spouse in your dependents?": "Is your spouse in your dependents?",
  "Is your spouse over 56 years old?": "Is your spouse over 56 years old?",
  "Do you have children?": "Do you have children?",
  "Do you have any children who meet the criteria of choice?":
    "Do you have any children who meet the criteria of choice?",
  "Did you live in Okinawa from April 1, 1958 to May 14, 1972 after you became an adult?":
    "Did you live in Okinawa from April 1, 1958 to May 14, 1972 after you became an adult?",
  "Before the end of compulsory education":
    "Before the end of compulsory education",

  Upload: "Upload",
  "Pension book": "Pension book",
  "Most recent employment insurance card":
    "Most recent employment insurance card",
  "Certificate of Family Register": "Certificate of Family Register",
  "Certificate of Residence with whole household":
    "Certificate of Residence with whole household",
  "Bank book/Cash card": "Bank book/Cash card",
  "Spouse's income certificate": "Spouse's income certificate",

  "Online Consultation": "Online Consultation",
  "Reservation Details": "Reservation Details",
  "Mobile phone number": "Mobile Phone Number",
  Email: "Email",
  "Choose Date": "Choose Date",
  "Reservable Time": "Reservable Time",
  Proceed: "Proceed",
  "Online Consultation is Successfully Reserved!":
    "Online Consultation is Successfully Reserved!",
  "The content of the created online consultation was sent by short message.":
    "The content of the created online consultation was sent by short message.",
  "Go To Meeting": "Go To Meeting",
  "Go To Meeting List": "Go To Meeting List",
  "Go To HomePage": "Go To HomePage",
  "Are you sure you want to cancel your schedule?":
    "Are you sure you want to cancel your schedule?",
  "All your updates will not be saved if you click ‘Cancel Changes’":
    "All your updates will not be saved if you click ‘Cancel Changes’",
  "Review Reservation Details": "Review Reservation Details",
  Reserve: "Reserve",
  "Update Details": "Update Details",
  Return: "Return",
  "Email is not a valid format": "Email is not a valid format",
  "Date and Time": "Date and Time",
  "Phone Type": "Phone Type",
  Help: "Help",
  "Smartphone window (reservation completed)":
    "Smartphone window (reservation completed)",
  "Thank you for the reservation": "Thank you for the reservation",
  "Reservation reception number": "Reservation reception number",
  "Check the SMS to see reservation details and enter the video call from the URL on the SMS.":
    "Check the SMS to see reservation details and enter the video call from the URL on the SMS.",

  "Thank you for using the smartphone window. We look forward to hearing from you in order to provide better services in the future.":
    "Thank you for using the smartphone window. We look forward to hearing from you in order to provide better services in the future.",
  Feedback: "Feedback",
  "How was the call quality?": "How was the call quality?",
  "How was the response of the person in charge?":
    "How was the response of the person in charge?",
  "Please write any additional comments.":
    "Please write any additional comments.",
  Send: "Send",
  Close: "Close",
  Correc: "Correct",
  "I Agree": "I Agree",
  "Consult Now": "Consult Now",
  "Specify the Date and Time": "Specify the Date and Time:",
  "Quick Contract": "Quick Contract",
  "Ask Operator": "Ask Operator",
  "Need Help with Operation": "Need Help with Operation",
  "Need Consultation": "Need Consultation",
  Other: "Other",
  "Consultation Date and Time": "Consultation Date and Time",
  "Terms & Condition": `XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX`,
  "Please Select": "Please Select",
  "Fill up form below to reserve your consultation meeting.":
    "Fill up form below to reserve your consultation meeting.",
  "Please input full name": "Please input full name",
  "Please input active mobile number": "Please input active mobile number",
  "Please input email address": "Please input email address",
  "Please review your details below.": "Please review your details below.",
  "I accept the Terms & Condition": "I accept the Terms & Condition",
  "Terms and Conditions": "Terms and Conditions",
  "Reservation Complete": "Reservation Complete",
  "Last Name": "Last Name",
  "First Name": "First Name",
  "Please input Last name": "Please input Last name",
  "Please input First name": "Please input First name",
  "Mobile Number should be <mobileLength> Digit":
    "Mobile Number should be <mobileLength> Digit",
  Surname: "Surname",
  "Preferred avatar for operator": "Preferred avatar for operator",
  "Preferred Avatar": "Preferred Avatar",
  "Female 1": "Female 1",
  "Female 2": "Female 2",
  "Male 1": "Male 1",
  "Male 2": "Male 2",

  // Consult Now
  "Keep Looking": "Keep Looking",
  "Reserve specific date and time": "Reserve specific date and time",
  "Please wait while we find available operators to assist you":
    "Please wait while we find available operators to assist you",
  "Oh no! All our operators are busy at the moment.":
    "Oh no! All our operators are busy at the moment.",
  "Yay, we have found an operator!": "Yay, we have found an operator!",
  "This may take a while.": "This may take a while.",
  "Hold on. We are still looking.": "Hold on. We are still looking.",
  "Please expect a call once meeting reservation has been confirmed.":
    "Please expect a call once meeting reservation has been confirmed.",
};
