import React, { FunctionComponent } from "react";
import { Translation } from "react-i18next";

import { Grid, Card, CardContent, Typography } from "@material-ui/core";

// styles
import "./styles.css";

const NeedToWait: FunctionComponent = (props) => {
  return (
    <Translation>
      {(t) => (
        <Grid container justify="center">
          <div className="Reservation-NeedToWait-whole-card">
            <CardContent>
              <Grid>
                <div className="Reservation-NeedToWait-text-below">
                  ただ今、混みあっております。お申込完了後10分程度お待ちいただく場合がございます。ご了承ください。

                </div>
              </Grid>
            </CardContent>
          </div>
        </Grid>
      )}
    </Translation>
  );
};

export default NeedToWait;
