import React, { useState, useEffect, useRef } from "react";

import { Grid } from "@material-ui/core";

import PromiseLogo from "../../../assets/images/LogoPromise.png";
import HelpIcon from "../../../assets/images/IconHelp.png";

import "./index.css";
import HelpModal from "../../Common/HelpModal";

interface AppbarProps {
  appbarTitle: string;
  handleCallback?: any;
  withCallback: any;
}

export default function Appbar(prop: AppbarProps) {
  const [helpState, setHelpState] = useState(false);

  const openHeplModal = () => {
    setHelpState(!helpState);
  };

  var modalRef = useRef<any>(null);
  var touchStartY = 0;

  useEffect(() => {
    if (helpState) {
      setTimeout(() => {
        addScrollControl();
      }, 200);
    } else {
      removeScrollControl();
    }
  }, [helpState]);

  const scrollControl = (e: any) => {
    if (!modalRef?.current) return;
    const modal = modalRef.current as HTMLElement;
    if (!modal.contains(e.target)) {
      e.preventDefault();
    }
    if (!modalRef?.current) return;
    const isScrollingDown =
      e.type === "mousewheel"
        ? e.wheelDelta < 0
        : touchStartY > e.changedTouches[0].pageY;
    if (isScrollingDown) {
      const modal = modalRef.current as HTMLElement;
      const isAtBottom =
        modal.scrollTop + modal.clientHeight >= modal.scrollHeight;
      if (isAtBottom && e.cancelable) {
        e.preventDefault();
      }
    } else {
      const modal = modalRef.current as HTMLElement;
      const isAtTop = modal.scrollTop === 0;
      if (isAtTop && e.cancelable) {
        e.preventDefault();
      }
    }
  };

  const addScrollControl = () => {
    document.addEventListener(
      "touchstart",
      (e: any) => {
        touchStartY = e.touches[0].pageY;
      },
      false
    );
    document.addEventListener("mousewheel", scrollControl, {
      passive: false,
    });
    document.addEventListener("touchmove", scrollControl, {
      passive: false,
    });
  };

  const removeScrollControl = () => {
    document.removeEventListener("mousewheel", scrollControl, false);
    document.removeEventListener("touchmove", scrollControl, false);
  };

  return (
    <Grid
      className="AppBar-root"
      key={localStorage.getItem("language") === "ja" ? "ja" : "en"}
    >
      <div className="AppBar-wrapper">
        <div className="AppBar-toolbar">
          <div className="AppBar-toolbar-div">
            <img className="promise-logo" alt="img" src={PromiseLogo} />
          </div>
          <button
            className="AppBar-helpIcon"
            onClick={() => {
              openHeplModal();
            }}
          >
            <img alt="img" className="promise-help" src={HelpIcon} />
            <div className="help-font">お問合せ</div>
          </button>
        </div>
      </div>
      {helpState ? (
        <HelpModal
          modalvisible={() => openHeplModal()}
          getRef={(ref: any) => {
            modalRef = ref;
          }}
          closeSetState={() => {
            setHelpState(!helpState);
          }}
        />
      ) : null}
    </Grid>
  );
}
