import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import Navigation from "../../lib/navigation";
import { ProtectedRoute } from "../../lib/protectedRoute";

import Reservation from "../Reservation";
import Review from "../Review";

import VirtualRoom from "../VirtualRoom";
import Confirmation from "../Confirmation";
import NoAvailableOperator from "../NoAvailableOperator";
import Feedback from "../Feedback";

import Error from "../Error";

class App extends React.Component {
  render() {
    const privateRoute = Navigation.getRoutes("private");
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/meeting"
              component={(...rest: any[]) => {
                return <VirtualRoom {...rest} />;
              }}
            />
            <Route
              exact
              path="/meeting/agent"
              component={(...rest: any[]) => {
                return <VirtualRoom {...rest} />;
              }}
            />
            <Route
              exact
              path="/reservation"
              component={(...rest: any[]) => {
                return <Reservation {...rest} />;
              }}
            />
            <Route
              exact
              path="/review"
              component={(...rest: any[]) => {
                return <Review {...rest} />;
              }}
            />
            <Route
              exact
              path="/confirmation"
              component={(...rest: any[]) => {
                return <Confirmation {...rest} />;
              }}
            />
            <Route
              exact
              path="/noavailableoperator"
              component={(...rest: any[]) => {
                return <NoAvailableOperator {...rest} />;
              }}
            />
            <Route
              exact
              path="/feedback"
              component={(...rest: any[]) => {
                return <Feedback {...rest} />;
              }}
            />
            <Route
              exact
              path="/error"
              component={(...rest: any[]) => {
                return <Error {...rest} />;
              }}
            />

            <main>
              {/* {privateRoute.map((route) => {
                let Component = route.component;
                return (
                  <ProtectedRoute
                    exact
                    path={route.path}
                    component={(...rest: any[]) => {
                      return <Component {...rest} />;
                    }}
                  />
                );
              })} */}
            </main>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
