import React, { FunctionComponent } from "react";
import EndPage from "../../../EndPage";

const onlineInstruction = process.env.REACT_APP_PROMISE_ONLINE_INSTRUCTION;

const NoAvailableOperator: FunctionComponent = (props) => {
  const goToReview = () => {
    window.location.href = onlineInstruction!.toString();
  };

  const contextOfEndPage = {
    title: "オンライン相談のお申込",
    noticeInfo: "お申込は完了しておりません。",
    innerHTML:
      "申し訳ございませんが、オンライン相談サービスへのお申込が集中しているか、または受付時間外となります。<br/>" +
      "しばらくたってから再度アクセスしていただくか、受付時間内のご利用をお待ちしております。<br/><br/>" +
      "オンライン相談サービスの受付時間は、9時~18時までとなります。",
    buttonName: "戻る",
    displayButton: true,
    clickEventHandler: goToReview,
  };

  return <EndPage {...contextOfEndPage} />;
};

export default NoAvailableOperator;
